<template>
  <div class="eventSwitch">
    <div id="maintablepage">
      <div class="maintablepage-content">
        <!-- 内容 -->
        <div class="contant_O">
          <div class="btnSite">
            <div class="ulBtn">
              <div v-for="(item, index) in btnList" :key="index"><span @click="btnSite(index)"
                  :style="{ background: indexSite == index ? '#fff' : '', color: indexSite == index ? '#000' : '#555' }">{{
                    item
                  }}</span></div>
            </div>
          </div>
          <div class="cont_z" v-show="!indexSite">
            <div class="echartsShow">
              <div>
                <div class="time-right">
                  <div class="type-group">
                    <span class="pick-type" v-for="(item, idx) in timetType" :key="idx" @click="changeType(item.code)">
                      <font :class="{ 'isactive-type': item.code == searchLeft.TYPE }">{{
                        item.name
                      }}</font>
                    </span>
                  </div>
                  <el-date-picker type="date" v-if="searchLeft.TYPE == '按天'" v-model="searchLeft.VALUE" placeholder="选择天"
                    :clearable="false">
                  </el-date-picker>
                  <el-date-picker type="month" v-if="searchLeft.TYPE == '按月'" v-model="searchLeft.VALUE" placeholder="选择月"
                    :clearable="false">
                  </el-date-picker>
                  <el-date-picker type="year" v-if="searchLeft.TYPE == '按年'" v-model="searchLeft.VALUE" placeholder="选择年"
                    :clearable="false">
                  </el-date-picker>
                </div>
                <div id="echartLeft"></div>
              </div>
              <div>
                <div class="time-right">
                  <div class="type-group">
                    <span class="pick-type" v-for="(item, idx) in timetType" :key="idx" @click="changeType1(item.code)">
                      <font :class="{ 'isactive-type': item.code == searchRight.TYPE }">{{
                        item.name
                      }}</font>
                    </span>
                  </div>
                  <el-date-picker type="date" v-if="searchRight.TYPE == '按天'" v-model="searchRight.VALUE"
                    placeholder="选择天" :clearable="false">
                  </el-date-picker>
                  <el-date-picker type="month" v-if="searchRight.TYPE == '按月'" v-model="searchRight.VALUE"
                    placeholder="选择月" :clearable="false">
                  </el-date-picker>
                  <el-date-picker type="year" v-if="searchRight.TYPE == '按年'" v-model="searchRight.VALUE"
                    placeholder="选择年" :clearable="false">
                  </el-date-picker>
                </div>
                <div id="echartRight"></div>
              </div>
            </div>
            <el-table :key="Math.random()" class="app-table" ref="tableList" @row-click="rowClick" :row-key="getRowKeys"
              :expand-row-keys="expands" @expand-change="expandChange" :data="tableList"  height="300px"
              style="width: 100%; margin-bottom: 16px;">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div class="row-column">
                    <el-form label-position="left" inline class="demo-table-expand">
                      <el-form-item label="事件编号">
                        <span>{{ props.row.EVENTINFORMATIONNUM }}</span>
                      </el-form-item>
                      <el-form-item label="事件触发时间">
                        <span>{{ props.row.CREATETIME }}</span>
                      </el-form-item>
                      <el-form-item label="可能产生原因">
                        <span>{{ props.row.CAUSE }}</span>
                      </el-form-item>
                      <el-form-item label="处理措施">
                        <span>{{ props.row.MEASURE }}</span>
                      </el-form-item>
                      <el-form-item label="当前值">
                        <span>{{ props.row.CURRENTVALUE }}</span>
                      </el-form-item>
                      <el-form-item label="测点">
                        <span>{{ props.row.VARIABLEREALNAME }}</span>
                      </el-form-item>
                      <el-form-item label="事件类型">
                        <span>{{ props.row.EVENTTYPE }}</span>
                      </el-form-item>
                      <el-form-item label="状态">
                        <span>{{ props.row.ALERTSTATUS }}</span>
                      </el-form-item>
                      <el-form-item label="处理人员">
                        <span>{{ props.row.PROCESSOR }}</span>
                      </el-form-item>
                    </el-form>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="事件编号" prop="EVENTINFORMATIONNUM" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="事件触发时间" prop="CREATETIME" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="测点" prop="VARIABLEREALNAME" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="事件类型" prop="EVENTTYPE" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="状态" prop="ALERTSTATUS" :show-overflow-tooltip="true">
              </el-table-column>
            </el-table>
            <div class="elPagination">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50, 60, 80, 100, 150, 200]" :page-size="10"
                layout="total, sizes, prev, pager, next, jumper" :total="totaltab">
              </el-pagination>
            </div>
          </div>
          <div class="cont_z" v-show="indexSite" style="height: 590px;">
            <!-- <div @click="add" class="btn">新增</div> -->
            <el-table :key="Math.random()" class="app-table" ref="rightTable" :data="tableData"  height="300px"
              style="width: 100%;margin-bottom: 16px;">
              <el-table-column label="编号" prop="RULEMASTERNUM" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="创建人" prop="CREATEBYNAME" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="创建时间" prop="CREATETIME" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="站点" prop="ASSETDEPTNAME" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="设备类型" prop="EQUIPMENTELTYPE" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="设备" prop="EQUIPMENTELNAME" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="测点台" prop="VARIABLEREALNAME" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="生效日期" prop="ENABLETIME" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="失效日期" prop="DISABLETIME" :show-overflow-tooltip="true">
              </el-table-column>
            </el-table>
            <div class="elPagination">
              <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
                :current-page="currentPage2" :page-sizes="[10, 20, 30, 40, 50, 60, 80, 100, 150, 200]" :page-size="10"
                layout="total, sizes, prev, pager, next, jumper" :total="totaltab2">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
export default {
  data() {
    return {
      searchLeft: {
        TYPE: "按天",
        VALUE: this.$moment()
      },
      searchRight: {
        TYPE: "按天",
        VALUE: this.$moment()
      },
      timetType: [
        {
          code: "按天",
          name: "按天",
        },
        {
          code: "按月",
          name: "按月",
        },
        {
          code: "按年",
          name: "按年",
        },
      ],
      indexSite: 0,
      btnList: ['事件信息', '事件规则'],
      expands: [],
      tableList: [],
      tableData: [],
      currentPage: 1,
      totaltab: 0,
      limit: "10",
      page: "1",
      currentPage2: 1,
      totaltab2: 0,
      limit2: "10",
      page2: "1",
      dataAll: {},
      dataRight: {}
    }
  },
  created() {

  },
  mounted() {
    this.getequipment()
    this.getRight()
    this.getrowData()
    this.getrightData()
  },
  watch: {
    'indexSite': {
      handler: function (val) {
        if (val) {
          this.expands = []
        }else{
          let chartID = document.getElementById('echartRight')
          this.echartsRight(chartID)
          let chartIDs = document.getElementById('echartLeft')
          this.echartsLeft(chartIDs)
        }
      },
      deep: true,
      immediate: true
    },
    'dataAll': {
      handler: function () {
        this.echartsLeft(document.getElementById('echartLeft'))
      },
      deep: true,
      immediate: true
    },
    'dataRight': {
      handler: function () {
        this.echartsRight(document.getElementById('echartRight'))
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    add(){
      let routeData = this.$router.resolve({
          path: "/generalApp/RULEMASTER",
          query: {
            type: "todo",
            gotoaddpage: true
          },
        });
        console.log(routeData.href);
        layer.open({
          type: 2,
          area: ["90%", "90%"],
          title: "新增规则",
          closeBtn: 1,
          content: routeData.href,
          end: function () {
           
          },
        });
    },
    changeType(type) {
      this.searchLeft.TYPE = type;
      this.getequipment()
    },
    changeType1(type) {
      this.searchRight.TYPE = type;
      this.getRight()
    },
    getequipment() {
      let type = 'day', startTime = '', endTime = ''
      if (this.searchLeft.TYPE == '按天') {
        type = 'day'
        startTime = this.$moment(this.searchLeft.VALUE).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        endTime = this.$moment(this.searchLeft.VALUE).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else if (this.searchLeft.TYPE == '按月') {
        type = 'month'
        startTime = this.$moment(this.searchLeft.VALUE).startOf('month').format('YYYY-MM-DD HH:mm:ss')
        endTime = this.$moment(this.searchLeft.VALUE).endOf('month').format('YYYY-MM-DD HH:mm:ss')
      }
      else if (this.searchLeft.TYPE == '按年') {
        type = 'year'
        startTime = this.$moment(this.searchLeft.VALUE).startOf('year').format('YYYY-MM-DD HH:mm:ss')
        endTime = this.$moment(this.searchLeft.VALUE).endOf('year').format('YYYY-MM-DD HH:mm:ss')
      }
      let data = {
        "queryType": type,
        "assetNumber": this.$store.state.ASSET.detailInfos.ASSETNUMBER,
        "startTime": startTime,
        "endTime": endTime
      }
      let url = Config.dev_url + "/api-apps/eventInfo/count/eventtype/equipment"
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$nextTick(() => {
            this.dataAll = res.data.data;
          })
        } else {
          this.$message(res.data.msg)
        }
      });
    },
    getRight() {
      let type = 'day', startTime = '', endTime = ''
      if (this.searchRight.TYPE == '按天') {
        type = 'day'
        startTime = this.$moment(this.searchRight.VALUE).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        endTime = this.$moment(this.searchRight.VALUE).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else if (this.searchRight.TYPE == '按月') {
        type = 'month'
        startTime = this.$moment(this.searchRight.VALUE).startOf('month').format('YYYY-MM-DD HH:mm:ss')
        endTime = this.$moment(this.searchRight.VALUE).endOf('month').format('YYYY-MM-DD HH:mm:ss')
      }
      else if (this.searchRight.TYPE == '按年') {
        type = 'year'
        startTime = this.$moment(this.searchRight.VALUE).startOf('year').format('YYYY-MM-DD HH:mm:ss')
        endTime = this.$moment(this.searchRight.VALUE).endOf('year').format('YYYY-MM-DD HH:mm:ss')
      }
      let data = {
        "queryType": type,
        "assetNumber": this.$store.state.ASSET.detailInfos.ASSETNUMBER,
        "startTime": startTime,
        "endTime": endTime
      }
      let url = Config.dev_url + "/api-apps/eventInfo/statsbyeventtype/equipment"
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$nextTick(() => {
            this.dataRight = res.data.data;
          })
        } else {
          this.$message(res.data.msg)
        }
      });
    },
    echartsLeft(chartID) {
      let chartInit = echarts.init(chartID);
      let dataValue = this.dataAll.valueList || []
      let seriesVal = []
      dataValue.forEach((item) => {
        seriesVal.push({
          name: item.eventtype,
          value: Number(item.cnt)
        })
      })
      var option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label:{
              show: false
            },
            labelLine: {
              show: false
            },
            data: seriesVal
          }
        ]
      };
      chartInit.setOption(option);
      chartInit.resize()
    },
    echartsRight(chartID) {
      let chartInit = echarts.init(chartID);
      var series = [], seriesData = this.dataRight.seriesData, categoryData = this.dataRight.dateList
      seriesData.forEach(item => {
        var obj = {
          type: 'bar',
          name: item.eventType,
          data: item.value,
          smooth: false,
        }
        series.push(obj)
      })
      var option = {
        animation: false,
        layoutAnimation: false,
        grid: {
          left: '5%',
          right: '5%',
          bottom: '10%',
          top: '20%',
          containLabel: true
        },
        legend: {
          right: 0,
          top: '5%',
          orient: "vertical",
          icon: "rect",
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: 'ABBvoice_WCNSG_Rg',
            color: '#1d2129'
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100,
            height: 20,
            bottom: 0,
          },
          {
            start: 0,
            end: 100,
            height: 20,
            bottom: 5,
          }
        ],
        xAxis: {
          type: 'category',
          data: categoryData,
          boundaryGap: true,
          nameTextStyle: {
            fontFamily: 'ABBvoice_WCNSG_Rg',
            color: '#9f9f9f',
            fontSize: 14
          },
          axisLine: {
            lineStyle: {
              color: '#1f1f1f'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            fontFamily: 'ABBvoice_WCNSG_Rg',
            color: '#696969',
            fontSize: 14
          },
        },
        yAxis: {
          type: 'value',
          name:"(次)",
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: 'ABBvoice_WCNSG_Rg',
            color: 'black',
            fontSize: 14
          },
          axisLine: {
            lineStyle: {
              color: '#1f1f1f'
            }
          },
          axisLabel: {
            fontFamily: 'ABBvoice_WCNSG_Rg',
            color: '#696969',
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#ebebee'
            }
          },
        },
        series: series
      };
      chartInit.setOption(option);
      chartInit.resize()
    },
    handleSizeChange(value) {
      this.limit = value;
      this.getrowData()
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getrowData()
    },
    handleSizeChange2(value) {
      this.limit2 = value;
      this.getrightData()
    },
    handleCurrentChange2(val) {
      this.page2 = val;
      this.getrightData()
    },
    btnSite(index) {
      this.indexSite = index
    },
    rowClick(row) {
      this.$refs.tableList.toggleRowExpansion(row);
    },
    expandChange(row, expandedRows) {
      if (expandedRows.length) {
        this.expands = [];
        if (row) {
          this.expands.push(row.ID);
        }
      } else {
        this.expands = [];
      }
    },
    getRowKeys(row) {
      return row.ID;
    },
    getrowData() {
      let MACHINE_NAME = this.$store.state.ASSET.detailInfos.MACHINE_NAME
      let dataList = { "page": this.page, "limit": this.limit, "p_order": "[]", "p_query": "{\"links\":[{\"key\":\"ASSETNAME\",\"links\":[],\"type\":\"Like\",\"value\":\"" + MACHINE_NAME + "\"}],\"type\":\"LinkAnd\"}" }
      this.$axios({
        url: Config.dev_url + "/api-apps/app/EVENTINFORMATION/list",
        method: "post",
        data: dataList
      }).then(res => {
        if (res.data.code == 0) {
          this.$nextTick(() => {
            this.tableList = res.data.data
            this.totaltab = Number(res.data.count)
          })
        } else {
          this.$message(res.data.msg)
        }
      })
    },
    getrightData() {
      let MACHINE_NAME = this.$store.state.ASSET.detailInfos.MACHINE_NAME
      let dataList = { "page": this.page2, "limit": this.limit2, "p_order": "[]", "p_query": "{\"links\":[{\"key\":\"EQUIPMENTELNAME\",\"links\":[],\"type\":\"Like\",\"value\":\"" + MACHINE_NAME + "\"}],\"type\":\"LinkAnd\"}" }
      this.$axios({
        url: Config.dev_url + "/api-apps/app/RULEMASTER/list",
        method: "post",
        data: dataList
      }).then(res => {
        if (res.data.code == 0) {
          this.$nextTick(() => {
            this.tableData = res.data.data
            this.totaltab2 = Number(res.data.count)
          })
        } else {
          this.$message(res.data.msg)
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.row-column {
  display: flex;
  flex-direction: column;
  background: white;

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 200px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }

  .demo-table-expand {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 30px;
  }
}

.eventSwitch {
  background: #fff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
}

#maintablepage .maintablepage-content {
  flex: auto;
  overflow-x: auto;
  overflow-y: auto;
}

.app-table .el-table__header thead tr th {
  padding: 0;
  height: 40px;
  background: #f2f3f5;
}
</style>
<style lang="less" scoped>
.btn{
    display: flex;
    align-items: center;
    background: #3366FF;
    color: white;
    justify-content: center;
    padding: 0px 15px;
    margin-right: 12px;
    border-radius: 3px;
    cursor: pointer;
    width: 70px;
    line-height: 30px;
    margin-top: 20px;
}
#maintablepage {
  margin: 0 !important;
  height: fit-content !important;
}

.btnSite {
  display: flex;
}

.ulBtn {
  display: flex;
  background: rgb(245, 245, 245);
  border-radius: 5px;

  &>div {
    display: flex;

    &>span {
      display: inline-block;
      padding: 0 8px;
      margin: 6px;
      border-radius: 3px;
      cursor: pointer;
      line-height: 18px;
      font-size: 14px;
    }
  }
}

.contant_O {
  height: 100%;
  overflow-y: scroll;
}

.cont_z {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .echartsShow {
    display: flex;
    width: 100%;

    &>div {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 430px;
      justify-content: center;
    }

    #echartLeft {
      width: 500px;
      height: 400px;
    }

    #echartRight {
      width: 700px;
      height: 400px
    }
  }
}

.elPagination {
  display: flex;
  justify-content: flex-end;
}

#maintablepage .maintablepage-content {
  overflow-y: auto !important;
}

.time-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 120px;
}

.isactive-type {
  color: #205de8 !important
}
/deep/.el-input__icon{
  line-height: 32px;
}
</style>
  