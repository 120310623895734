import { set } from "vue";

export function getallData(config,_this,name, setObj) { //1、环境变量 2、当前this 3、当前页面名字(英文) 4、存储的对象数据
    let url = config.dev_url + "/api-user/sysPageLang/findLangDetail";
    let data = {
      'menukey': name,//当前页面的英文名称
      'langcode': ''
    }
    _this.$axios.post(url, data).then((res) => {
      // 当前页面的所有的中英文数据
      setTimeout(() => {
        if(_this.$store.state.jump == true){
          sessionStorage.setItem( setObj, JSON.stringify(res.data.data))
          _this.$store.commit("setjump", false);
        }else{
          sessionStorage.setItem( setObj, JSON.stringify(res.data.data))
          _this.$store.commit("setjump", true);
         
        }
      }, 100);
     
      
      
  });
}

// // 引入获取当前页面中英文国际化数据
// import {getallData} from "./getCurrent"
// // 1、环境变量 2、当前this 3、当前页面名字(英文) 4、存储的对象数据
// getallData(Config,this,'defaultIndex',"currentData") //总览-当前
// JSON.parse(sessionStorage.getItem(setObj)) 获取存储当前的页面中英文数据