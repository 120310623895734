<template>
  <div style="   padding: 15px;background: white;padding-top: 0px;height: 565px;">
    <div style="display:flex;align-items: center;">
      <div style="width: 3px;height: 12px;background: #F9BA08;margin-right: 9px;"></div>
      <div>设备模型</div>
    </div>
    <div style="width:100%;justify-content: center;display: flex;">
      <img style="width: 72%;" src="@/assets/imgs/fadong.jpg" />
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      
    }
  },
  computed:{
   
  },
  watch: {

  },
  methods: {
    
  },
  mounted(){
    
  }
}
</script>

<style scoped lang='less'>

</style>
