import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


import navindex from "@/views/navindex/navindex.vue"
const routes = [
    {
        path: '*',
        name: 'notFound',
        component: () => import('@/views/notFound.vue'),
        meta: {
            title: "404",
        }
    },
    //登录页
    {
        path: '/',
        name: 'login',
        component: () => import('@/views/login.vue'),
        meta: {
            title: "登录",
        }
    },
    //大屏1
    {
        path: '/bigscreen1',
        name: 'bigscreen1',
        component: () => import('@/views/bigscreen/big1.vue'),
        meta: {
            title: "大屏",
        }
    },
    //大屏1
    {
        path: '/bigscreen2',
        name: 'bigscreen2',
        component: () => import('@/views/bigscreen/bigscreen.vue'),
        meta: {
            title: "大屏",
        }
    },
    {
        path: '/bigscreen3',
        name: 'bigscreen3',
        component: () => import('@/views/bigscreen2/bigscreen.vue'),
        meta: {
            title: "大屏",
        }
    },
    //注册页
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/register.vue'),
    },
    {
        path: '/navindex',
        name: 'navindex',
        component: navindex,
        children: [
            // 能源管理widget
            {
                path: "/powerSource",
                name: "powerSource",
                component: () => import('@/views/widget/powerSource.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            // 碳widget
            {
                path: "/carbon",
                name: "carbon",
                component: () => import('@/views/widget/powerSource.vue'),
                // component: () => import('@/views/widget/carbon.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            // 低压开关柜 widget
            {
                path: "/lvscabinetA",
                name: "lvscabinetA",
                component: () => import('@/views/widget/powerSource.vue'),
                // component: () => import ('@/views/widget/lvscabinetA.vue'),
                meta: {
                    requiresAuth: true
                }
            },

            // 默认首页
            {
                path: "/defaultIndex",
                name: "defaultIndex",
                component: () => import('@/views/widget/powerSource.vue'),
                // component: () => import('@/views/widget/overview.vue'),
                meta: {
                    requiresAuth: true,
                    title: "首页",
                }
            },
            // 节能分析
            {
                path: "/analysis",
                name: "analysis",
                component: () => import('@/views/widget/analysis.vue'),
                meta: {
                    requiresAuth: true
                }
            },

            // 运维widget
            {
                path: "/project",
                name: "project",
                component: () => import('@/component/operationPlanning/index.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            // 运维widget
            // {
            //     path: "/newreport",
            //     name: "newreport",
            //     component: () => import('@/views/report/report.vue'),
            //     meta: {
            //         requiresAuth: true
            //     }
            // },
            // 运维widget
            {
                path: "/operation",
                name: "operation",
                component: () => import('@/views/widget/powerSource.vue'),
                // component: () => import ('@/views/widget/lvscabinetA.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            // 设备widget
            {
                path: "/equipment",
                name: "equipment",
                component: () => import('@/views/widget/equipment.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            // 能源widget
            {
                path: "/energy",
                name: "energy",
                component: () => import('@/views/widget/energy.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            // 报告首页 home page
            {
                path: "/homePage",
                name: "homePage",
                component: () => import('@/views/report/homePage.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            // 报告首页 home page
            {
                path: "/deviceDetails",
                name: "deviceDetails",
                // src\views\bigscreen\financeView\finance.vue
                component: () => import('@/views/bigscreen/financeView/finance.vue'),//src\component\deviceAsse\OMP.vue
                meta: {
                    requiresAuth: true
                }
            },
            // 能源报告
            {
                path: "/energyReport",
                name: "energyReport",
                component: () => import('@/views/report/energyReport.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            // 设备报告
            {
                path: "/deviceReport",
                name: "deviceReport",
                component: () => import('@/views/report/deviceReport.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            // 运维报告
            {
                path: "/opexReport",
                name: "opexReport",
                component: () => import('@/views/report/opexReport.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            // 碳排放报告
            {
                path: "/carbonReport",
                name: "carbonReport",
                component: () => import('@/views/report/carbonReport.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            // 能源widget
            {
                path: "/newreport",
                name: "newreport",
                component: () => import('@/views/report/report.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            
            // 研发产品
            {
                path: "/productGeneral/*",
                name: "productGeneral",
                meta: {
                    requiresAuth: true
                }
            },
            // 首页
            // {
            //     path: "/index",
            //     name: "index",
            //     component: () => import('@/views/index.vue'),
            //     meta: {
            //         requiresAuth: true
            //     }
            // },
            //门户设计
            {
                path: "/getewayGeneral/*",
                name: "getewayGeneral",
                meta: {
                    requiresAuth: true
                }
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    //to即将进入的目标路由对象，from当前导航正要离开的路由， next : 下一步执行的函数钩子
    console.log(to)
    document.title = 'ABB 智慧能源平台 - ' + (!!to.query.title ? to.query.title : (!!to.meta.title ? to.meta.title : ''))
    if (to.meta.requiresAuth && !sessionStorage.getItem('application_access_token')) {
        next({ path: '/' })
    } else { next() }
})

export default router
