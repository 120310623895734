<template>
  <div class="opexPlan">
    <div class="title">
      运维计划
    </div>
    <div class="opexPlan-contant">
      <div>
        <div>
          <span>上次维护</span>
          <span>{{ detailInfo.upTime }}</span>
        </div>
        <div class="imgDiv"><img src="../../assets/imgs/time01.png" alt=""></div>
      </div>
      <div>
        <div>
          <span>下次维护时间</span>
          <span>{{ detailInfo.downTime }}</span>
        </div>
        <div class="imgDiv"><img src="../../assets/imgs/time02.png" alt=""></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'opexPlan',
  components: {},
  data() {
    return {
      detailInfo: {
        upTime: '',
        downTime: ''
      }
    }
  },
  watch: {},
  computed: {},
  methods: {
    getData() {
      let data = {
        "assetNum": this.$store.state.ASSET.detailInfos.ASSETNUMBER,
        "startTime": this.$moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        "endTime": this.$moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
      }
      let url = Config.dev_url + "/api-apps-v2/api/v2/equipment/cycle/plan"
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$nextTick(() => {
            if (res.data.data) {
              this.detailInfo.upTime = res.data.data.lastoperationdate ? res.data.data.lastoperationdate : ''
              this.detailInfo.downTime = res.data.data.nextoperationdate ? res.data.data.nextoperationdate : ''
            }
          })
        } else {
          this.$message(res.data.msg)
        }
      });
    },
  },
  mounted() {
    let vm = this
    setTimeout(() => {
      vm.getData()
    }, 1000)
    this.detailInfo.upTime = this.$store.state.ASSET.detailInfos.LASTOPERATIONDATE
  }
}
</script>

<style lang="less" scoped>
.opexPlan {
  background: #fff;
  padding: 20px;
  height: 265px;

  .title {
    font-size: 16px;
    font-weight: 600;
    color: #1f1f1f;
    border-left: 4px solid #FF8C00;
    padding-left: 10px;
    display: flex;
    line-height: 15px;
  }
}

.opexPlan-contant {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 10px;
  justify-content: space-evenly;

  & > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: flex;
      flex-direction: column;

      & > span:nth-child(1) {
        line-height: 22px;
      }

      & > span:nth-child(2) {
        font-size: 24px;
        font-weight: 600;
        line-height: 38px;
      }
    }
  }

  .imgDiv {
    width: 77px;
    height: 77px;
    margin: 14px;
  }
}
</style>
    