import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './axios/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'ant-design-vue/dist/antd.css';
//引入图标js文件
import '@/assets/icon/iconfont.css'
import '@/assets/menuicon/iconfont.css'
import '@/assets/css/theme.css'
import '@/assets/css/reset.less';
import '@/assets/css/ABB_reset.less';
import Antd from 'ant-design-vue';
import CH from 'ant-design-vue/es/locale/zh_CN';
import US from 'ant-design-vue/es/locale/en_US';
import 'whatwg-fetch';
import 'custom-event-polyfill';
import 'core-js/stable/promise';
import 'core-js/stable/symbol';
import 'core-js/stable/string/starts-with';
import 'core-js/web/url';
import actions from '../actions';
import dataV from '@jiaminghi/data-view'
import TDesign from 'tdesign-vue';
import i18n from "./lang/index"
// 引入组件库全局样式资源
import 'tdesign-vue/es/style/index.css';
// import 'default-passive-events'
//自定义组件
import custom1 from '@/component/custom/custom1.vue';
import custom2 from '@/component/custom/custom2.vue';
import custom3 from '@/component/custom/custom3.vue';
import custom4 from '@/views/report/deviceDetails.vue';
//  src\component\deviceAsse\deviceModel.vue
import deviceModel from '@/component/deviceAsse/deviceModel.vue';
import latestEvents from '@/component/deviceAsse/latestEvents.vue';
import loadRate from '@/component/deviceAsse/information.vue';
import opexPlan from '@/component/deviceAsse/opexPlan.vue';
import eventSwitch from '@/component/deviceAsse/eventSwitch.vue';
import ompSite from '@/component/deviceAsse/OMP.vue';
window.commonComponent = {custom1,custom2,custom3,custom4,deviceModel,latestEvents,loadRate,opexPlan,eventSwitch,ompSite}
Vue.use(TDesign);
Vue.use(CH);
Vue.use(US);
Vue.use(dataV)
Vue.prototype.$CH = CH;
Vue.prototype.$US = US;
Vue.prototype.$actions = actions;
import layer from "layui-layer"
Vue.use(ElementUI);
Vue.use(Antd);
import * as echarts from "echarts"
Vue.prototype.$echarts = echarts;

import moment from 'moment'//导入文件
moment.locale('zh-cn');//需要汉化
Vue.prototype.$moment = moment;//赋值使用

import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()
import {message} from "@/resetMessage";
Vue.prototype.$message = message

import { Message, MessageBox } from 'element-ui'  //引入Message, MessageBox

Vue.prototype.$message = Message                //vue实例上挂载Message                
Vue.prototype.$messagebox = MessageBox             //vue实例上挂载MessageBox             
//引入子应用（研发产品）
import "./qiankun"
function bringValue(obj, bringValue, index) {
    let bringVal = bringValue.split(",");
    //带值返回将，当前的弹框中的值，放到上一弹窗的共享值里面
    for (let i = 0; i < bringVal.length; i++) {
        let field = bringVal[i].split("=");
        store.state.shareDetailInfo[index][field[0]] = obj[field[1]];
    }
}
//子表带值返回
function childBringValue(obj, bringValue, index) {
    let bringVal = bringValue.split(",");
    //带值返回将，当前的弹框中的值，放到上一弹窗的共享值里面
    for (let i = 0; i < bringVal.length; i++) {
        let field = bringVal[i].split("=");
        store.state.shareChildDetailInfo[index][field[0]] = obj[field[1]];
    }
}
function putShareValue(frameindex, backdetailInfo) {
    store.state.shareDetailInfo[frameindex] = backdetailInfo;
}
function putChildShareValue(frameindex, childdetailInfo) {
    store.state.shareChildDetailInfo[frameindex] = childdetailInfo;
}
window.bringValue = bringValue;
window.putShareValue = putShareValue;
window.putChildShareValue = putChildShareValue
window.childBringValue = childBringValue;

Vue.config.productionTip = false
const setFontSize= () => {
const htmlDom = document.getElementsByTagName('html')[0];
let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
if (htmlWidth >= 750) {
    htmlWidth = 750;
}
if (htmlWidth <= 320) {
    htmlWidth = 320;
}
htmlDom.style.fontSize = `${htmlWidth / 7.5}px`
};
window.onresize = setFontSize;
setFontSize()
let vueThis = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

function commonLangHandler(enname ,chname, messageSign){
    let lang = sessionStorage.getItem("locale") || 'CH';
    let commonList = JSON.parse(sessionStorage.getItem('currentDataAll'))
    console.log(lang)
    console.log(commonList)
    console.log(JSON.parse(sessionStorage.getItem('currentDataAll')))
    let res = ''
    console.log(res = commonList[enname][lang])
    if(!!commonList[enname] && !!commonList[enname][lang]){
        res = commonList[enname][lang]
    }else{
        res = chname
    }
  
    return res
}
function LangHandler(enname ,chname){
    let lang = sessionStorage.getItem("locale") || 'CH';
    let commonList = JSON.parse(sessionStorage.getItem("commonLang"))
  
    let res = ''
    if(!!commonList[lang] && !!commonList[lang][enname]&&commonList[lang][enname].length!==0){
        res = commonList[lang][enname]
    }else{
        res = chname
    }
    
    return res
}
Vue.prototype.commonLangHandler = commonLangHandler;
Vue.prototype.LangHandler = LangHandler;
Vue.prototype.getZEdata = JSON.parse(sessionStorage.getItem('currentDataAll')) || {}
// export async function bootstrap() {
// }
//
// /**
//  * 应用每次进入都会调用 mount 方法，通常我们在这里触发应用的渲染方法
//  */
// export async function mount(props) {
// }
//
// /**
//  * 应用每次 切出/卸载 会调用的方法，通常在这里我们会卸载微应用的应用实例
//  */
// export async function unmount(props) {
// }
//
// /**
//  * 可选生命周期钩子，仅使用 loadMicroApp 方式加载微应用时生效
//  */
// export async function update(props) {
//     console.log('update props', props);
// }
