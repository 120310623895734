<template>
  <div class="latestEvents">
    <div class="flex_title">
      <div class="title_name">
        最新事件
      </div>
    </div>
    <div class="latestEvents-contant">
      <el-table class="app-table" ref="tableList" :data="tableData">
        <el-table-column label="事件时间" prop="CREATETIME"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="测点" prop="VARIABLEREALNAME" width="130" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="事件状态" width="120" prop="ALERTSTATUS">
        </el-table-column>
        <el-table-column label="事件类型" width="120" prop="EVENTTYPE">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'latestEvents',
  components: {},
  data() {
    return {
      tableData: [],
    }
  },
  props: {
    querydata: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    getData() {
     
     
      // 设备名称查询
      let MACHINE_NAME = this.$store.state.ASSET.detailInfos.MACHINE_NAME
      let dataList = { "page": 1, "limit": 20, "p_order": "[]", "p_query": "{\"links\":[{\"key\":\"ASSETNAME\",\"links\":[],\"type\":\"Like\",\"value\":\"" + MACHINE_NAME + "\"}],\"type\":\"LinkAnd\"}" }
      this.$axios({
        url: Config.dev_url + "/api-apps/app/EVENTINFORMATION/list",
        method: "post",
        data: dataList
      }).then(res => {
         
        this.tableData = res.data.data
      })
    },
  },
  mounted() {
    let vm = this
    setTimeout(() => {
      vm.getData()
    }, 100);
    
  }
}
</script>
      
<style lang="less" scoped>
.latestEvents {
  background: #fff;
  padding: 20px;
  height: 350px;
}

/deep/.card-head-title {
  width: 100%;
}

.flex_title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title_name {
    font-size: 16px;
    font-weight: 600;
    color: #1f1f1f;
    border-left: 4px solid #FF8C00;
    padding-left: 10px;
    text-align: center;
    display: flex;
    line-height: 15px;
  }
}

.app-table {
  width: 100%;
  border-top: 1px solid #e4e4e4;
  padding-top: 24px;
}
/deep/.app-table .el-table__empty-block{
  min-height: 0 !important;
}
</style>
      