<template>
  <div id="app" style="width: 100%;height: 100%;">
     <a-config-provider :locale="atlocale =='EN'? EN : CH">
       <router-view/>
     </a-config-provider>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {getallData} from "./getCurrent"
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
export default {
    
    created(){
      sessionStorage.setItem( "jump", 0)
      dayjs.locale('en');
      //在页面加载时读取sessionStorage里的状态信息
      if (sessionStorage.getItem("store")) {
        this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store"))))
      }
      //在页面刷新时将vuex里的信息保存到sessionStorage里
      window.addEventListener("beforeunload", () => {
        sessionStorage.setItem("store", JSON.stringify(this.$store.state))
      })
    },
    computed:{
      atlocale() {
        return this.$i18n.locale
      },
      CH() {
        console.log(this.US)
        return this.$CH
      },
      EN() {
        console.log(this.US)
        return this.$US
      },
        ...mapState(['currentuserinfo'])
    },
    watch: {
      atlocale: {
        handler: function (newVal, oldVal) {
         if (newVal) {
          if(newVal == "EN"){
            dayjs.locale('en');
          }else{
            dayjs.locale('cn');
          }
        }
        },
        deep: true,
        immediate: true,
      },
      "option.visible": {
        //全屏化时执行
        handler: function (newVal, oldVal) {
          if (newVal) {
            if (this.isModal) {
              this.searchObj = this.orginSearchObj;
              this.getData();
            }
          }
        },
        deep: true,
        immediate: true,
      },
      "currentSelectDeptInfo.CODE": {
        handler: function (newVal, oldVal) {
          if (oldVal) {
            this.type = [];
            this.getData();
          }
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {
      var _this = this;
      //监听父应用传递的用户信息
      this.$actions.onGlobalStateChange((state) => { //监听全局状态
          console.log("本项目 接收到的数据",{...state})
          
          if(!!state.customEventInfo && Object.keys(state.customEventInfo).length > 0) {
              _this.$store.commit("setcustomEventInfo",state.customEventInfo)
          } else {
            _this.$store.commit("setcustomEventInfo",{})
          }
      }, true);
        //设置默认主题
        // if(this.currentuserinfo && !!this.currentuserinfo.NAVSTYLE){
        //     document.documentElement.setAttribute("theme-mode",this.currentuserinfo.NAVSTYLE);
        // }else{
            document.documentElement.setAttribute("theme-mode", "light");
        // }
        // if(this.currentuserinfo && !!this.currentuserinfo.THEMECOLOR){
        //     document.documentElement.setAttribute("theme-color",this.currentuserinfo.THEMECOLOR);
        // }else{
            document.documentElement.setAttribute("theme-color", "#1C74F1");
        // }
    }
}
</script>

<style lang="less">
</style>
