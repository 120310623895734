<template>
  <div class="OperationCom7">
    <div class="contant_t">
      <el-form label-position="left" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
        class="demo-ruleForm">
        <el-form-item :label="LangHandler('site','站点')" prop="regionName">
          <el-input :placeholder="LangHandler('sesite','请选择站点')" v-model="ruleForm.regionName" :readonly="true" @click.native="onclick('站点')">
          </el-input>
          <i class="el-icon-search icon_style" @click="onclick('站点')"></i>
        </el-form-item>
        <el-form-item :label="LangHandler('funcitontype','功能分类')" prop="region2">
          <el-select v-model="ruleForm.region2" :placeholder="LangHandler('sefuncitontype','请选择功能分类')" @change="onChange">
            <el-option v-for="item in newarr" :key="item.id" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="LangHandler('eqtype','设备类型')" prop="region3">
          <el-select v-model="ruleForm.region3" :placeholder="LangHandler('seeqtype','请选择设备类型')" @change="onChange1">
            <el-option v-for="item in newarr1" :key="item.id" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="LangHandler('template','模板')" prop="region1Name">
          <el-input :placeholder="LangHandler('setemplate','请选择模板')" v-model="ruleForm.region1Name" :readonly="true" @click.native="onclick('模板')">
          </el-input>
          <i class="el-icon-search icon_style" @click="onclick('模板')"></i>
        </el-form-item>
        <el-form-item :label="LangHandler('eq','设备')" prop="region4Name">
          <el-input :placeholder="LangHandler('seeq','请选择设备')" v-model="ruleForm.region4Name" :readonly="true" @click.native="onclick('设备')">
          </el-input>
          <i class="el-icon-search icon_style" @click="onclick('设备')"></i>
        </el-form-item>
        <el-form-item :label="LangHandler('timeframe','时段')">
          <el-date-picker v-model="ruleForm.date1" type="daterange" :range-separator="LangHandler('until','至')" :start-placeholder="LangHandler('starttime','开始日期')"
            :end-placeholder="LangHandler('endtime','结束日期')" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="LangHandler('dataframe','数据范围')">
          <el-radio-group v-model="ruleForm.resource">
            <el-radio :label="LangHandler('minutes','15分钟')"></el-radio>
            <el-radio :label="LangHandler('rawdata','原始数据')"></el-radio>
          </el-radio-group>
          <div class="tipsDiv">
            <p v-if="ruleForm.resource == '15分钟'">{{LangHandler('onemonth','时间范围近一个月的时间')}}</p>
            <p v-else style="margin-left: 130px;">{{LangHandler('threeday','时间范围近三天的时间')}}</p>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="footerBtn el-dialog__footer">
      <el-button size="mini" @click="xbtn">{{LangHandler('cancle','取 消')}}</el-button>
      <el-button size="mini" @click="download" type="primary" style="background: #3366ff">{{LangHandler('export','导出')}}</el-button>
    </span>
    <el-dialog :title="titleName" v-if="dialogVisible2" :visible.sync="dialogVisible2" :append-to-body="true"
      class="reseDialogt custom-dialog" width="50%">
      <div class="elDialog">
        <div class="elDialog_div">
          <span v-if="columnNum == '站点'">{{LangHandler('deptid','部门编号')}}</span><span v-if="columnNum == '模板' || columnNum == '设备'">{{LangHandler('type','类型')}}</span><el-input
            v-model="input" :placeholder="LangHandler('inputdeptid','请输入部门编号')"></el-input>
        </div>
        <div class="elDialog_div">
          <span v-if="columnNum == '站点'">{{LangHandler('deptname','部门名称')}}</span>
          <span v-if="columnNum == '模板' || columnNum == '设备'">{{LangHandler('dname','名称')}}</span><el-input v-model="input1"
            :placeholder="LangHandler('inputdeptname','请输入部门名称')"></el-input>
        </div>
        <div class="elDialog_div">
          <el-button size="mini" type="primary" style="background: #1c74f1;" @click="onclick(columnNum)">{{LangHandler('o9query','查询')}}</el-button>
          <el-button size="mini" @click="resetting">{{LangHandler('o9reset','重置')}}</el-button>
        </div>
      </div>
      <div class="elTable">
        <el-table :data="tableData" style="width: 100%;padding: 10px 0;" @selection-change="selectionChange"
          :default-sort="{ prop: 'date', order: 'descending' }" highlight-current-row
          @current-change="handleCurrentChange2">
          <el-table-column v-if="columnNum == '设备'" type="selection" width="55">
          </el-table-column>
          <el-table-column v-if="columnNum == '站点'" prop="CODE" :label="LangHandler('deptid','部门编号')" sortable>
          </el-table-column>
          <el-table-column v-if="columnNum == '站点'" prop="FULLNAME" :label="LangHandler('deptname','部门名称')" sortable>
          </el-table-column>
          <el-table-column v-if="columnNum == '模板' || columnNum == '设备'" prop="MACHINE_TYPE" :label="LangHandler('type','类型')" sortable>
          </el-table-column>
          <el-table-column v-if="columnNum == '设备'" prop="MACHINE_NAME" :label="LangHandler('dname','名称')" sortable>
          </el-table-column>
          <el-table-column v-if="columnNum == '模板'" prop="TEMPDESC" :label="LangHandler('dname','名称')" sortable>
          </el-table-column>
          <el-table-column v-if="columnNum == '设备'" prop="ASSETNUMBER" :label="LangHandler('oid','编号')" sortable>
          </el-table-column>
          <el-table-column v-if="columnNum == '模板'" prop="TNUM" :label="LangHandler('oid','编号')" sortable>
          </el-table-column>
        </el-table>
      </div>
      <div class="elPagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
          :page-sizes="[10, 20, 30, 40, 50, 60, 80, 100, 150, 200]" :page-size="10"
          layout="total, sizes, prev, pager, next, jumper" :total="totaltab">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">{{LangHandler('cancle','取 消')}}</el-button>
        <el-button type="primary" @click="dialogVisible2 = false">{{LangHandler('confirm','确 定')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectDate: "",
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = "";
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== "") {
            const one = this.ruleForm.resource == "15分钟" ? 30 * 24 * 3600 * 1000 : 2 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        },
      },
      totaltab: 0,
      ruleForm: {
        region: "",
        regionName: "",
        region1: "",
        region1Name: "",
        region2: "",
        region3: "",
        region4: "",
        region4Name: "",
        date1: [],
        resource: "15分钟",
      },
      input: "",
      input1: "",
      columnNum: "",
      titleName: "",
      tableData: [],
      newarr: [],
      newarr0: [],
      newarr1: [],
      currentPage4: 1,
      limit: "10",
      page: "1",
      dialogVisible2: false,
      rules: {
        regionName: [
          { required: true, message: "请选择站点", trigger: "change" },
        ],
        region1Name: [
          { required: true, message: "请选择模板", trigger: "change" },
        ],
        region2: [
          { required: true, message: "请选择功能分类", trigger: "change" },
        ],
        region3: [
          { required: true, message: "请选择设备类型", trigger: "change" },
        ],
        region4Name: [
          { required: true, message: "请选择设备", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    dialogVisible2(val) {
      if (!val) {
        this.input = ''
        this.input1 = ''
      }
    },
    dialogVisible2() {
      this.currentPage4 = 1
      this.limit = "10"
      this.page = "1"
    },
    'ruleForm.region2': {
      handler: function () {
        this.ruleForm.region3 = ''
      }
    },
    'ruleForm.region3': {
      handler: function () {
        this.ruleForm.region1 = ""
        this.ruleForm.region1Name = ""
        this.ruleForm.region4 = ""
        this.ruleForm.region4Name = ""
      }
    },
    'ruleForm.region1Name': {
      handler: function () {
        this.ruleForm.region4 = ""
        this.ruleForm.region4Name = ""
      }
    },
    'ruleForm.date1': {
      handler: function (val) {
        if (!val) {
          this.selectDate = ''
        }
      }
    },
    'ruleForm.resource': {
      handler: function () {
        this.selectDate = ''
        this.ruleForm.date1 = []
      }
    },
    deep: true,
    immediate: true
  },
  methods: {
    xbtn() {
      this.$parent.$parent.dialogVisible1 = false
    },
    formatter(row, column) {
      return row.address;
    },
    download() {
      if (this.ruleForm.regionName == "" || this.ruleForm.region2 == "" || this.ruleForm.region3 == "" || this.ruleForm.region1Name == "" || this.ruleForm.region4Name == "") {
        this.$message.warning("您有必选项未选择！");
        return
      }
      var queryType = "";
      var startTime = "";
      var endTime = "";
      if (this.ruleForm.resource == "15分钟") {
        queryType = "mintue15";
      } else if (this.ruleForm.resource == "原始数据") {
        queryType = "mintue";
      }
      if (this.ruleForm.date1.length != 0) {
        startTime = this.ruleForm.date1[0];
        endTime = this.ruleForm.date1[1];
      }
      let data = {
        equipmentType: this.ruleForm.region3,
        templateNum: this.ruleForm.region1,
        deptCode: this.ruleForm.region,
        queryType: queryType,
        equipmentNum: this.ruleForm.region4,
        startTime: startTime,
        endTime: endTime,
      };
      let url =
        Config.dev_url +
        `/api-apps-v2/api/v2/telemetry/data/export?equipmentType=${this.ruleForm.region3}&templateNum=${this.ruleForm.region1}&deptCode=${this.ruleForm.region}&queryType=${queryType}&equipmentNum=${this.ruleForm.region4}&startTime=${startTime}&endTime=${endTime}`;
      console.log(`equipmentType=${this.ruleForm.region3}&templateNum=${this.ruleForm.region1}&deptCode=${this.ruleForm.region}&queryType=${queryType}&equipmentNum=${this.ruleForm.region4}&startTime=${startTime}&endTime=${endTime}`, '导出入参')

      this.$axios({url:url,method:"GET",responseType: 'blob'}).then(function(response){


        const disposition = response.headers['content-disposition'];
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'file.xlsx';

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        // 设置下载的文件名
        link.setAttribute('download', decodeURIComponent(filename));

        // 将 <a> 元素添加到页面，并触发下载
        document.body.appendChild(link);
        link.click();

        // 释放 URL 对象
        window.URL.revokeObjectURL(url);
      }) .catch(function (error) {
      console.error('Error downloading file:', error);
    });

      //window.open(url);
      this.$parent.$parent.dialogVisible1 = false
      // this.$axios.post(url, data).then((res) => {
      //   console.log(res.data);
      //   if (res.data != " " && res.data.data != null) {
      //     let Disposition = res.request.getResponseHeader(
      //       "Content-Disposition"
      //     );
      //     const reg = /filename=.*\.xlsx;/g;
      //     const arr = Disposition.match(reg);
      //     const realName = arr[0].substring(9, arr[0].length - 1);
      //     this.blobDownload(res.data.data, decodeURIComponent(realName));
      //   } else if (res.data != " " && res.data != null && res.data.constructor != Object) {
      //     let Disposition = res.request.getResponseHeader(
      //       "Content-Disposition"
      //     );
      //     const reg = /filename=.*\.xlsx;/g;
      //     const arr = Disposition.match(reg);
      //     const realName = arr[0].substring(9, arr[0].length - 1);
      //     this.blobDownload(res.data, decodeURIComponent(realName));
      //   } else {
      //     this.$message.warning(res.data.msg);
      //   }
      // });
    },

    // blobDownload(data, name) {
    //   name = name || "csdn搜xuelang532777032";
    //   var content = data;
    //   var data = new Blob([content], {
    //     type: "application/vnd.ms-excel;charset=utf-8",
    //   });
    //   var downloadUrl = window.URL.createObjectURL(data);
    //   console.log(downloadUrl);
    //   var anchor = document.createElement("a");
    //   anchor.href = downloadUrl;
    //   anchor.download = name;
    //   anchor.click();
    //   window.URL.revokeObjectURL(data);
    //   this.$message.success("下载成功");
    // },
    onclick(e) {
      let data = {};
      let url = "";
      if (e == "站点") {
        data = {
          page: this.page,
          limit: this.limit,
          DEPTTYPE_wheres: "站点",
          CODE: this.input,
          FULLNAME: this.input1,
        };
        this.columnNum = "站点";
        this.titleName = "请选择站点";
        url = Config.dev_url + "/api-apps/app/DEPT/list";
      } else if (e == "模板") {
        data = {
          page: this.page,
          limit: this.limit,
          MACHINE_TYPE: this.ruleForm.region3,
          TEMPDESC: this.input,
        };
        this.columnNum = "模板";
        this.titleName = "请选择模板";
        url = Config.dev_url + "/api-apps/app/DIANBIAO/list";
      } else if (e == "设备") {
        data = {
          page: this.page,
          limit: this.limit,
          DEPTNAME: this.ruleForm.regionName,
          EQUPMENT_MODELI: this.ruleForm.region2,
          TEMPNUM: this.ruleForm.region1,
          MACHINE_NAME: this.input1,
          MACHINE_TYPE: this.input,
        };
        this.columnNum = "设备";
        this.titleName = "请选择设备";
        url = Config.dev_url + "/api-apps/app/EQUIPMENT/list";
      }
      this.$axios.post(url, data).then((res) => {
        this.tableData = res.data.data;
        this.totaltab = Number(res.data.count)
      });
      this.dialogVisible2 = true;
    },
    findByCodes() {
      let url = Config.dev_url + "/api-user/dicts/findByCodes";
      let data = {
        code: "EQUPMENT_MODELI,QUARTER,DJLX,IOTTYPE,CHAIFA,ASSET_LEVEL,EVENTTYPE",
      };
      this.$axios.post(url, data).then((res) => {
        this.newarr = res.data.EQUPMENT_MODELI.subDicts
        for (const key in res.data) {
          if (Object.hasOwnProperty.call(res.data, key)) {
            const element = res.data[key];
            this.newarr0.push(element);
          }
        }
      });
    },
    onChange(item) {
      console.log(item)
      this.newarr0.forEach((val) => {
        console.log(val)
        if (item == '配电' && '配电采集设备类型' == val.name) {
          this.newarr1 = val.subDicts;
        } else if (item == '光伏' && '光伏采集设备类型' == val.name) {
          this.newarr1 = val.subDicts;
        } else if (item == '储能' && '储能采集设备类型' == val.name) {
          this.newarr1 = val.subDicts;
        } else if (item == '柴发' && '柴发' == val.name) {
          this.newarr1 = val.subDicts;
        } else if (item == '充电桩' && '充电桩' == val.name) {
          this.newarr1 = val.subDicts;
        }
      });
    },
    onChange1(item) {
      this.ruleForm.region3 = item;
    },
    resetting() {
      (this.input = ""), (this.input1 = ""), this.onclick(this.columnNum);
    },
    handleSizeChange(value) {
      this.limit = value;
      this.onclick(this.columnNum);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.onclick(this.columnNum);
    },
    handleCurrentChange2(val) {
      if (val != null) {
        if (this.columnNum == "站点") {
          this.ruleForm.region = val.CODE;
          this.ruleForm.regionName = val.FULLNAME;
        } else if (this.columnNum == "模板") {
          this.ruleForm.region1 = val.TNUM;
          this.ruleForm.region1Name = val.TEMPDESC;
        }
      }
    },
    selectionChange(val) {
      let valList = val
      let value = [], valueName = []
      if (valList.length > 10) {
        this.$message.warning("最多只能选择10条数据！");
        return
      } else {
        valList.forEach((item) => {
          value.push(item.ASSETNUMBER)
          valueName.push(item.MACHINE_NAME)
        })
      }
      this.ruleForm.region4 = value.join(',');
      this.ruleForm.region4Name = valueName.join(',')
    }
  },
  mounted() {
    this.findByCodes();
  },
};
</script>
<style  lang="less">
.OperationCom7 {
  position: relative;

  .title_n {
    padding: 20px 0 20px 20px;
  }

  .contant_t {
    margin-left: 12px;
    padding: 20px;
  }

  .el-select-dropdown {
    min-width: 0 !important;
  }

  .el-date-editor--daterange.el-input__inner {
    width: 320px;
    height: 32px;
  }

  .el-date-editor .el-range__icon {
    line-height: 25px;
  }

  .el-date-editor .el-range-separator {
    width: 8%;
    line-height: 25px;
  }

  .dialog-footer {
    margin-left: 80%;
  }
}

.reseDialogt {
  .elDialog {
    width: 100%;
    display: flex;

    .elDialog_div {
      display: flex;
      margin-left: 20px;

      span {
        display: inline-block;
        width: 90px;
        height: 32px;
        line-height: 32px;
      }

      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }

      .el-button {
        padding: 0;
        height: 32px;
        line-height: 32px;
      }
    }
  }

  .el-table__body-wrapper {
    height: 400px;
    overflow-y: auto;
  }
}

.elPagination {
  display: flex;
  justify-content: flex-end;
}

.icon_style {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.footerBtn {
  display: flex;
  justify-content: flex-end;
}
</style>
<style lang="less" scoped>
/deep/.el-form .el-form-item .el-form-item__content .el-input .el-input__inner {
  cursor: pointer;
}

/deep/.el-form .el-form-item .el-form-item__content .el-radio-group .el-radio {
  padding: 0px 24px;
}

.tipsDiv {
  display: flex;
  font-size: 12px;
  color: red;
  line-height: 12px;
}
</style>