<template>
  <div class="deviceModel">
    <div class="title">
      设备模型
    </div>
    <div class="deviceModel-contant">
      <div class="deviceModel-left">
        <img src="../../assets/imgs/mechanical.png" alt="" srcset="">
      </div>
      <div class="deviceModel-right">
        <!-- <div class="statustips">
          <div>
            <span>工作状态</span>
            <span>{{ list1.state }}</span>
          </div>
          <div>
            <span>报警状态</span>
            <span style="color:red">{{ list1.type }}</span>
          </div>
        </div> -->
        <ul>
          <li v-for="(item, index) in columns" :key="index">
            <span>{{ item.title }}</span>
            <span>{{ item.value }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'deviceModel',
  components: {},
  data() {
    return {
      isLoading: false,//数据加载中
      columns: [
        { title: '厂家', value: '' },
        { title: '型号', value: '' },
        { title: '生产日期', value: '' },
        { title: '机编号', value: '' },
      ],
      list1: {
        state: '',
        type: ''
      },
      detailInfo: {},
    }
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 }
      }
    },
    watchWidth: {
      type: Object,
      default: function () {
        return { w: '698' }
      }
    },
  },
  watch: {
  },
  computed: {},
  methods: {
    resizeEcharts(echart) {
      let vm = this
      const elementResizeDetectorMaker = require('element-resize-detector')// 引入监听dom变化的组件
      const erd = elementResizeDetectorMaker()
      // 监听id为echart的元素 大小变化
      erd.listenTo(echart, function (element) {
        vm.getData()
      })
    },
    getData() {
       
        console.log(this.$store.state.ASSET.detailInfos.ASSETNUMBER)
        
      this.detailInfo = {};
      let data = {
        "assetNum": this.$store.state.ASSET.detailInfos.ASSETNUMBER,
        "variableName": ['alarm_output_indicator_status','engine_operating_state']
      }
       
      let url = Config.dev_url + "/api-apps-v2/api/v2/equipment/variable/real/value";
      
      this.$axios.post(url, data).then((res) => {
       
       console.log(res)
        let allData = res.data.data
       
        console.log(":::::::");
        console.log(allData)
        
        allData.forEach((item) => {
          if (item.variablename == 'alarm_output_indicator_status') {
           
                if(item.realtimevalue == 0){
                  this.$set(this.list1,'type','正常')
                }else if(item.realtimevalue == 1){
                  this.$set(this.list1,'type','报警')
                }else{
                  this.$set(this.list1,'type','未知')
                }
          }
          if (item.variablename == 'engine_operating_state') {
         
                if(item.realtimevalue == 0){
                  this.$set(this.list1,'state','停机')
                }else if(item.realtimevalue == 1){
                  this.$set(this.list1,'state','预启动')
                }else if(item.realtimevalue == 2){
                  this.$set(this.list1,'state','启动中')
                }else if(item.realtimevalue == 3){
                  this.$set(this.list1,'state','热机')
                }else if(item.realtimevalue == 4){
                  this.$set(this.list1,'state','运行')
                }else if(item.realtimevalue == 5){
                  this.$set(this.list1,'state','冷却中')
                }else if(item.realtimevalue == 6){
                  this.$set(this.list1,'state','停机中')
                }else if(item.realtimevalue == 7){
                  this.$set(this.list1,'state','运行后')
                }
          }
        })
        // if(res.data.data[0].realtimevalue == 0){
        //   this.$set(this.list1,'type','正常')
        // }else if(res.data.data[0].realtimevalue == 1){
        //   this.$set(this.list1,'type','报警')
        // }else{
        //   this.$set(this.list1,'type','未知')
        // }

        // if(res.data.data[1].realtimevalue == 0){
        //   this.$set(this.list1,'state','停机')
        // }else if(res.data.data[1].realtimevalue == 1){
        //   this.$set(this.list1,'state','预启动')
        // }else if(res.data.data[1].realtimevalue == 2){
        //   this.$set(this.list1,'state','启动中')
        // }else if(res.data.data[1].realtimevalue == 3){
        //   this.$set(this.list1,'state','热机')
        // }else if(res.data.data[1].realtimevalue == 4){
        //   this.$set(this.list1,'state','运行')
        // }else if(res.data.data[1].realtimevalue == 5){
        //   this.$set(this.list1,'state','冷却中')
        // }else if(res.data.data[1].realtimevalue == 6){
        //   this.$set(this.list1,'state','停机中')
        // }else if(res.data.data[1].realtimevalue == 7){
        //   this.$set(this.list1,'state','运行后')
        // }
        
        
      });
    
    },

  },
  mounted() {
    let echart = document.getElementById("pane-总览")
    this.resizeEcharts(echart)
    this.getData()
    let a = { title: '厂家', value: this.$store.state.ASSET.detailInfos.MANUFACTURER }
    let b = { title: '型号', value: this.$store.state.ASSET.detailInfos.MACHINE_MODEL }
    let c = { title: '生产日期', value: this.$store.state.ASSET.detailInfos.EXFACTORYTIME }
    let d = { title: '机编号', value: this.$store.state.ASSET.detailInfos.FACTORYNO }
    this.$set(this.columns,0,a)
    this.$set(this.columns,1,b)
    this.$set(this.columns,2,c)
    this.$set(this.columns,3,d)
    // this.list1.state = this.$store.state.ASSET.detailInfos.EQUPMENT_STATUS
    // this.columns[0] = this.$store.state.ASSET.detailInfos.MANUFACTURER
    // this.columns[1] = this.$store.state.ASSET.detailInfos.MACHINE_MODEL
    // this.columns[2] = this.$store.state.ASSET.detailInfos.EXFACTORYTIME
  }
}
</script>
  
<style lang="less" scoped>
.deviceModel {
  background: #fff;
  padding: 20px;
  height: 338px;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 16px;
    font-weight: 600;
    color: #1f1f1f;
    border-left: 4px solid #FF8C00;
    padding-left: 10px;
    display: flex;
    line-height: 15px;
  }
}


.deviceModel-contant {
  display: flex;
  flex: 1;
  align-items: center;

  img {
    width: 250px;
    height: 159px;
    position: relative;
    top: 20px;
  }

  &>div {
    flex: 1;
  }

  .deviceModel-left {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .deviceModel-right {
    .statustips {
      display: flex;
      width: 100%;
      text-align: left;

      &>div {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-bottom: 12px;
        &>span {
          color: #595959;
          flex: 1;
          font-weight: 500;
          line-height: 35px;
        }

        &>span:nth-child(2) {
          font-size: 30px;
          color: #51A34B;
        }
      }
    }

    &>ul {
      li {
        display: flex;
        justify-content: flex-start;
        border: 1px solid #e1e1e1;
        height: 40px;
        font-weight: 600;

        &>span {
          flex: 0.6;
          line-height: 40px;
        }

        &>span:nth-child(1) {
          padding-left: 15px;
        }

        &>span:nth-child(2) {
          background: rgb(245, 245, 245);
          border-left: 1px solid #e1e1e1;
          text-align: center;
          flex: 0.4;
        }
      }
    }
  }
}
</style>
  