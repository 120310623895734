<template>
  <div class="devOps">
    <div id="maintablepage">
      <div class="maintablepage-content">
        <!-- 内容 -->
        <div class="contant_O">
          <div class="msrStr" style="position: absolute;top: 25px;">运维计划</div>
          <div class="cont_z">
            <div style="width: 100%;display: flex;margin-bottom: 20px; justify-content: space-between;">
              <div class="time-right">
                <div class="type-group">
                  <span class="pick-type">
                    <font class="isactive-type">时间选择</font>
                  </span>
                </div>
                <el-date-picker v-model="times" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss"
                                :clearable="false" :append-to-body="false">
                </el-date-picker>
              </div>
            </div>
            <div class="bottom_txt">
              <div style="margin-right: 40px;">上次运维时间: {{ opexObj.lastTime }}</div>
              <div>预计下次运维时间: {{ opexObj.nextTime }}</div>
            </div>

            <div class="echarts" id="echartsO"></div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts"

export default {
  data() {
    return {
      opexObj: {
        lastTime: '',
        nextTime: ''
      },
      times: [this.$moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), this.$moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')],
      dataAll: {
        'chatX': []
      },
      chart: {}
    }
  },
  created() {
  },
  mounted() {

    this.getData()
    let echart = document.getElementById("pane-运维计划")
    this.init()
    this.resizeEcharts(echart)
  },
  watch: {
    'times': {
      handler: function () {
        this.getData()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    resizeEcharts(echart) {
      let vm = this
      const elementResizeDetectorMaker = require('element-resize-detector')// 引入监听dom变化的组件
      const erd = elementResizeDetectorMaker()
      // 监听id为echart的元素 大小变化
      erd.listenTo(echart, function (element) {

        vm.init()
      })
    },

    init() {
      this.chart = echarts.init(document.getElementById('echartsO'));
      let datalist = this.dataAll.chatX
      let todayBefore = [], todayAfter = [], xAxisList = [], markPoint = [];
      let toDay = this.$moment().format('YYYY-MM-DD')
      if (datalist.length !== 0) {
        datalist.forEach((item) => {
          // moment.utc().format("X") 时间戳
          if (this.$moment(item.name).utc().format("X") == this.$moment(toDay).utc().format("X") || this.$moment(item.name).utc().format("X") < this.$moment(toDay).utc().format("X")) {
            todayBefore.push(item)
            if (this.$moment(item.name).utc().format("X") !== this.$moment(toDay).utc().format("X")) {
              todayAfter.push({
                value: '-',
                name: item.name
              })
            } else {
              todayAfter.push(item)
            }
          } else {
            todayAfter.push(item)
          }
          
          if (this.$moment(item.name).utc().format("X") == this.$moment(toDay).utc().format("X")) {
            markPoint = [{
              value: "今日",
              coord: [item.name, item.value],
            }]
          }
          xAxisList.push(item.name)
        })
      } else {
        todayBefore = [];
        todayAfter = [];
      }
      var option = {
        animation: true,
        backgroundColor: 'white',
        layoutAnimation: false,
        color: ['#F9BA08'],
        grid: {
          left: "5%",
          right: "3%",
          bottom: "15%",
          top: "8%",
          containLabel: true,
        },
        legend: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          formatter: function (params) {
            let str = "";
            str += params[0].name + "<br>";
            str += `<div><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${params[0].color};margin-right:4px;"></span><span style="display:inline-block;margin-right:40px;">${params[0].value}</span></div>`
            return str;
          }
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            height: 20,
            bottom: 0,
          },
          {
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ],
        yAxis: {
          type: 'value',
          name:"(本次运维累计运行小时数)",
          axisTick: {
            show: false
          },
          nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "black",
              fontSize: 14,
            },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#DCE2E8'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#556677'
            }
          },
          splitLine: {
            show: false
          }
        },
        xAxis: {
          type: "category",
          data: xAxisList,
          // boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
        },
        series: [
          {
            type: 'line',
            smooth: true,
            label: {
              normal: {
                show: false,
                position: 'top'
              }
            },
            data: todayBefore,
            markPoint: {
              silent: true,
              label: {
                position: 'inside',
                show: true,
                color: '#ffffff'
              },
              data: markPoint
            }
          },
          {
            type: 'line',
            symbol: 'circle', // 折线点设置为实心点
            symbolSize: 6, // 折线点的大小
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                  type: 'dotted'
                }
              }
            },
            data: todayAfter
          }
        ],
      }

      this.chart.setOption(option);
      this.chart.resize()
    },
    getData() {

      let data = {
        "assetNum": this.$store.state.ASSET.detailInfos.ASSETNUMBER,
        "startTime": this.times[0],
        "endTime": this.times[1]
      }
      let url = Config.dev_url + "/api-apps-v2/api/v2/equipment/cycle/plan"
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$nextTick(() => {
            if (res.data.data) {
              this.dataAll = res.data.data;
              this.opexObj.lastTime = res.data.data.lastoperationdate ? res.data.data.lastoperationdate : ''
              this.opexObj.nextTime = res.data.data.nextoperationdate ? res.data.data.nextoperationdate : ''
            }
            this.init()
          })
        } else {
          this.$message(res.data.msg)
        }
      });
    },
  }
}
</script>
<style lang="less">
.row-column {
  display: flex;
  flex-direction: column;
  background: white;
}

.time_group {
  width: 75%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.time-right {
  display: flex;
  align-items: baseline;
}

.devOps .pick-type {
  margin: 0 20px;
  cursor: pointer;

  .isactive-type {
    color: #FF8C00 !important;
  }
}

.devOps {
  background: #fff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
}

.devOps #maintablepage {
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#maintablepage .maintablepage-content .maintablepage-header {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#maintablepage .maintablepage-content {
  flex: auto;
  overflow-x: auto;
  overflow-y: auto;
}

.app-table .el-table__header thead tr th {
  padding: 0;
  height: 40px;
  background: #f2f3f5;
}
</style>
<style lang="less" scoped>
#echartsO {
  width: 100%;

  height: 430px;
  margin: auto;
  margin-bottom: 10px;
}

.msrStr {
  font-weight: bold;
  font-size: 16px;
  border-left: 4px solid #FF8C00;
  padding-left: 10px;
  color: #000;
  line-height: 20px;
  position: absolute;
  top: 20px;
}

.contant_O {
  overflow-y: scroll;
}

.cont_z {
  padding: 10px 0;
  margin-top: 20px
}


/deep/ .el-input__inner {
  height: 32px !important;
}

/deep/ .el-input__icon {
  line-height: 26px !important;
}

/deep/ .el-range-separator {
  line-height: 26px !important;
}

.bottom_txt {
  display: flex;
  justify-content: flex-end;
  margin-right: 71px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 40px;

  & > div {
    margin: 10px 0;
    text-align: end;
  }
}

/deep/ .el-picker-panel__footer {
  .el-button--text {
    display: none !important;
  }
}
</style>