<template>
  <div class="information">
    <div class="title">发动机运行情况</div>
    <div class="btnSite">
      <div class="ulBtn">
        <div v-for="(item, index) in btnList" :key="index"><span @click="btnSite(item, index)"
            :style="{ background: indexSite == index ? '#fff' : '', color: indexSite == index ? '#000' : '#555' }">{{ item
            }}</span></div>
      </div>
    </div>
    <div class="information-content" v-if="!indexSite">
      <div id="chart1" style="height:100%;width:100%"></div>
      <div id="chartCenter" style="height:100%;width:100%"></div>
      <div id="chart2" style="height:100%;width:100%"></div>
    </div>
    <div class="information-bottom" v-if="!indexSite">
      <div class="leftRight">
        <div v-for="(item, index) in leftList" :key="index">
          <div class="div1"><span>{{ item.name }}</span><span>{{ item.value }}{{item.unit}}</span></div>
          <div class="div2">
            <span :style="{ width: item.rote, background: 'rgb(249,186,8)' }"></span>
          </div>
          <div class="div3"><span>{{ item.min }}</span><span>{{ item.max }}</span></div>
        </div>
      </div>
      <div class="center">
        <ul>
          <!-- <li v-for="(item, index) in spanList" :key="index">
            <div>{{ item.name }}</div>
            <div>{{ item.value }}</div>
          </li> -->
          <li>
            <div>负载率(%)</div>
            <div>{{ objAll.load }}</div>
          </li>
          <li>
            <div>系统电压(V)</div>
            <div>{{ objAll.voltage }}</div>
          </li>
          <li>
            <div>燃油消耗率(L/h)</div>
            <div>{{ objAll.rate }}</div>
          </li>
          <li>
            <div>总燃油消耗(L)</div>
            <div>{{ objAll.burned }}</div>
          </li>
        </ul>
      </div>
      <div class="leftRight">
        <div v-for="(item, index) in rightList" :key="index">
          <div class="div1"><span>{{ item.name }}</span><span>{{ item.value }}{{item.unit}}</span></div>
          <div class="div2">
            <span :style="{ width: item.rote, background: 'rgb(249,186,8)' }"></span>
          </div>
          <div class="div3"><span>{{ item.min }}</span><span>{{ item.max }}</span></div>
        </div>
      </div>
    </div>
    <!-- 详情信息 -->
    <div class="centerSite" v-else>
      <!-- <div class="center_news" v-for="(item, index) in newsList" :key="index">
        <div class="name_line">{{ item.name }} <div></div>
        </div>
        <ul>
          <li v-for="(item, index) in item.arrList" :key="index">
            <span>{{ item.name }}</span>
            <span :style="{ color: (item.name).indexOf('状态') > -1 ? 'rgb(12,169,25)' : '#000' }">{{ item.value }}</span>
          </li>
        </ul>
      </div> -->
      <!-- 运行基本信息 -->
      <div class="center_news">
        <div class="name_line">运行基本信息<div></div>
        </div>
        <ul>
          <li>
            <span>运行状态</span>
            <span style="color: rgb(12,169,25)">{{ objAll.operating }}</span>
          </li>
          <li>
            <span>负载率(%)</span>
            <span>{{ objAll.load }}</span>
          </li>
          <li>
            <span>主油门位置(%)</span>
            <span>{{ parseFloat(objAll.throttle).toFixed(1) }}</span>
          </li>
          <li>
            <span>系统电压(V)</span>
            <span>{{ objAll.voltage }}</span>
          </li>
          <!-- <li>
            <span>期望发动机转速(rpm)</span>
            <span>{{ Math.round(objAll.engine) }}</span>
          </li> -->
          <li>
            <span>发动机运行小时(h)</span>
            <span>{{ parseFloat(objAll.hours).toFixed(1) }}</span>
          </li>
          <li>
            <span>发动机转速(rpm)</span>
            <span>{{ Math.round(objAll.speed) }}</span>
          </li>
          <li>
            <span>瞬时燃油消耗率(L/h)</span>
            <span>{{ objAll.rate }}</span>
          </li>
          <li>
            <span>总燃油消耗(L)</span>
            <span>{{ objAll.burned }}</span>
          </li>
        </ul>
      </div>
      <!-- 冷却系统 -->
      <div class="center_news">
        <div class="name_line">冷却系统<div></div>
        </div>
        <ul>
          <li>
            <span>后冷却器冷却液温度(°C)</span>
            <span>{{ objAll.coolant }}</span>
          </li>
          <li>
            <span>冷却液温度(°C)</span>
            <span>{{ objAll.temperature }}</span>
          </li>
        </ul>
      </div>
      <!-- 进气系统 -->
      <div class="center_news">
        <div class="name_line">进气系统<div></div>
        </div>
        <ul>
          <!-- <li>
            <span>空气切断阀状态</span>
            <span style="color: rgb(12,169,25)">{{ objAll.shutoff }}</span>
          </li> -->
          <li>
            <span>1#空气过滤器压差(kPa)</span>
            <span>{{ Math.round(objAll.pressure) }}</span>
          </li>
          <li>
            <span>2#空气过滤器压差(kPa)</span>
            <span>{{ Math.round(objAll.pressure2) }}</span>
          </li>
          <!-- <li>
            <span>进气压力(kPa)</span>
            <span>{{ objAll.inlet }}</span>
          </li> -->
          <li>
            <span>进气温度(°C)</span>
            <span>{{ 21.5 }}</span>
            <!-- <span>{{ objAll.airtemperature }}</span> -->
          </li>
          <li>
            <span>增压器压力(kPa)</span>
            <span>{{ objAll.boost }}</span>
          </li>
          <!-- <li>
            <span>1#进气歧管温度(°C)</span>
            <span>{{ objAll.manifold1 }}</span>
          </li> -->
          <!-- <li>
            <span>2#进气歧管温度(°C)</span>
            <span>{{ objAll.manifold2 }}</span>
          </li> -->
        </ul>
      </div>
      <!-- 润滑油系统 -->
      <div class="center_news">
        <div class="name_line">润滑油系统<div></div>
        </div>
        <ul>
          <li>
            <span>曲轴箱压力(kPa)</span>
            <span>{{ parseFloat(objAll.crankcase).toFixed(1) }}</span>
          </li>
          <li>
            <span>机油过滤器压差(kPa)</span>
            <span>{{ Math.round(objAll.differential) }}</span>
          </li>
          <li>
            <span>机油压力(kPa)</span>
            <span>{{ objAll.oilpressure }}</span>
          </li>
          <!-- <li>
            <span>机油温度(°C)</span>
            <span>{{ parseFloat(objAll.oiltemperature).toFixed(1) }}</span>
          </li> -->
        </ul>
      </div>
      <!-- 排气系统 -->
      <div class="center_news">
        <div class="name_line">排气系统<div></div>
        </div>
        <ul>
          <!-- <li>
            <span>排气温度(°C)</span>
            <span>{{ Math.round(objAll.gas) }}</span>
          </li> -->
          <li>
            <span>右歧管排气温度(°C)</span>
            <span>{{ Math.round(objAll.manifold3) }}</span>
          </li>
          <li>
            <span>左歧管排气温度(°C)</span>
            <span>{{ Math.round(objAll.manifold4) }}</span>
          </li>
        </ul>
      </div>
      <!-- 燃油系统 -->
      <div class="center_news">
        <div class="name_line">燃油系统<div></div>
        </div>
        <ul>
          <li>
            <span>燃油过滤器压差(kPa)</span>
            <span>{{ objAll.fuelfilter }}</span>
          </li>
          <li>
            <span>燃油压力(kPa)</span>
            <span>{{ objAll.fuelpressure }}</span>
          </li>
          <!-- <li>
            <span>燃油温度(°C)</span>
            <span>{{ objAll.fueltemperature }}</span>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
export default {
  name: "information",
  components: {},
  props: {
    watchWidth: {
      type: Object,
      default: function () {
        return { w: '698' }//460px
      },
    },
  },
  watch: {
   
    currentDetailTabsModel(val) {
      
      if(val == "总览"){
        this.initEchart();
        this.initEchart2();
        this.initEchartZ();
      }
      
    },
    indexSite(val) {
      
      this.$nextTick(() => {
        this.getData()
      })
      if (!val) {
        this.$nextTick(() => {
          this.initEchart();
          this.initEchart2();
          this.initEchartZ();
          this.init()
        })
      }
    },
    'objAll': {
      handler: function () {
        setTimeout(() => {
          this.$nextTick(() => {
            this.init()
          })
        }, 700)
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      objAll: {
        speedMax1: 0,
        speedMax2: 0,
        speedMax: 0,
        load: '',
        throttle: '',
        voltage: '',
        engine: '',
        hours: '',
        speed: '',
        rate: '',
        burned: '',
        operating: '',
        coolant: '',
        temperature: '',
        shutoff: '',
        pressure: '',
        pressure2: '',
        inlet: '',
        airtemperature: '',
        boost: '',
        manifold1: '',
        manifold2: '',
        crankcase: '',
        differential: '',
        oilpressure: '',
        oiltemperature: '',
        fuelfilter: '',
        fuelpressure: '',
        fueltemperature: '',
        gas: '',
        manifold3: '',
        manifold4: ''
      },
      newsList: [
        {
          name: '运行基本信息',
          arrList: [
            {
              name: '运行状态',
              value: '正常'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负22222载率',
              value: '125%'
            }
          ]
        },
        {
          name: '进气系统',
          arrList: [
            {
              name: '空气切断阀状态',
              value: '正常'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负22222载率',
              value: '125%'
            }
          ]
        },
        {
          name: '冷却系统',
          arrList: [
            {
              name: '后冷却器冷却液温度',
              value: '210°C'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负载率',
              value: '125%'
            },
            {
              name: '负22222载率',
              value: '125%'
            }
          ]
        }
      ],
      indexSite: 0,
      btnList: ['基本信息', '详细信息'],
      btnName: '基本信息',
      spanList: [
        {
          name: '负载率(%)',
          value: 125
        },
        {
          name: '系统电压(V)',
          value: 3212.75
        },
        {
          name: '燃油消耗率(L/h)',
          value: 412.75
        },
        {
          name: '总燃油消耗(L)',
          value: '122,720.0'
        }
      ],
      leftList: [
        {
          name: '燃油过滤器压差',
          value: 0,
          min: 0,
          max: 0,
          rote: 0,
          unit:"kPa"
        },
        // {
        //   name: '1#进气歧管温度',
        //   value: 0,
        //   min: 0,
        //   max: 0,
        //   rote: 0,
        //   unit:"°C"
        // },
        // {
        //   name: '2#进气歧管温度',
        //   value: 0,
        //   min: 0,
        //   max: 0,
        //   rote: 0,
        //   unit:"kPa"
        // }
      ],
      rightList: [
        {
          name: '机油过滤器压差',
          value: 0,
          min: 0,
          max: 0,
          rote: 0,
          unit:"kPa"
        },
        {
          name: '歧管排气温度(R)',
          value: 0,
          min: 0,
          max: 0,
          rote: 0,
          unit:"°C"
        },
        {
          name: '歧管排气温度(L)',
          value: 0,
          min: 0,
          max: 0,
          rote: 0,
          unit:"°C"
        }
      ],
      searchObj: {},
      chartOption1: {},
      chartOption2: {},
      detailInfo: {
        chart1: {},
      },
    };
  },
  computed: {
    currentDetailTabsModel() {
      return this.$store.state.ASSET.currentDetailTabsModel
    },
  },
  methods: {
    
    perceCalc(min, max, num) {
      let perce = 0;
      if (isNaN(min) || isNaN(max) || isNaN(num)) {
        perce = 0
      } else {
        if ((Number(max) - Number(min)) == 0 || (Number(num) - Number(min)) == 0) {
          perce = 0
        } else {
          perce = (Number(num) - Number(min)) / (Number(max) - Number(min))
        }
      }
      if (perce < 0 || perce == 0) {
        return 0
      } else {
        return perce * 100 + '%'
      }
    },
    init() {
      this.initEchart();
      this.initEchart2();
      this.initEchartZ();
    },
    btnSite(item, index) {
      this.indexSite = index
      this.btnName = item
    },
    // 获取数据 
    getData() {
      
      this.detailInfo = {};
      let data = {
        "assetNum": this.$store.state.ASSET.detailInfos.ASSETNUMBER,
        "variableName": [
          'load',
          'primary_throttle_position',
          'aftercooler_coolant_temperature',
          'air_shutoff_status',
          'air_filter_differential_pressure_1',
          'air_filter_differential_pressure_2',
          'air_inlet_pressure',
          'air_inlet_temperature',
          'battery_voltage',
          'boost_pressure',
          'coolant_temperature',
          'crankcase_pressure',
          'desired_engine_speed',
          'engine_hours',
          'engine_speed',
          'exhaust_gas_temperature',
          'right_manifold_exhaust_gas_temperature',
          'left_manifold_exhaust_gas_temperature',
          'alarm_output_indicator_status',
          'engine_overspeed_verify',
          'fuel_filter_differential_pressure',
          'fuel_pressure',
          'fuel_temperature',
          'fuel_rate',
          'intake_manifold_1_temperature',
          'intake_manifold_2_temperature',
          'oil_filter_differential_pressure',
          'oil_pressure',
          'oil_temperature',
          'fuel_burned',
          'engine_operating_state'
        ]
      }
      let url = Config.dev_url + "/api-apps-v2/api/v2/equipment/variable/real/value";
      this.$axios.post(url, data).then((res) => {
        
        let allData = res.data.data
      
        allData.forEach((item) => {
          console.log(item.variablename)
          if (item.variablename == 'load') {
            
            this.objAll.load = item.realtimevalue //1
          }
          if (item.variablename == 'primary_throttle_position') {
            this.objAll.throttle = item.realtimevalue
          }
          if (item.variablename == 'battery_voltage') {
            this.objAll.voltage = item.realtimevalue //1
          }
          if (item.variablename == 'desired_engine_speed') {
            this.objAll.engine = item.realtimevalue
          }
          if (item.variablename == 'engine_hours') {
            this.objAll.hours = item.realtimevalue
          }
          if (item.variablename == 'engine_speed') {
            this.objAll.speed = item.realtimevalue //?
            this.objAll.speedMax = Number(item.valuemax)
          }
          if (item.variablename == 'fuel_rate') {
            this.objAll.rate = item.realtimevalue //1
          }
          if (item.variablename == 'fuel_burned') {
            this.objAll.burned = item.realtimevalue //1
          }
          if (item.variablename == 'engine_operating_state') {
            this.objAll.operating = item.realtimevalue
          }
          if (item.variablename == 'aftercooler_coolant_temperature') {
            this.objAll.coolant = item.realtimevalue // 图1
            this.objAll.speedMax1 = Number(item.valuemax)
          }
          if (item.variablename == 'coolant_temperature') {
            this.objAll.temperature = item.realtimevalue // 2
            this.objAll.speedMax2 = Number(item.valuemax)
          }
          if (item.variablename == 'air_shutoff_status') {
            this.objAll.shutoff = item.realtimevalue
          }
          if (item.variablename == 'air_filter_differential_pressure_1') {
            this.objAll.pressure = item.realtimevalue
          }
          if (item.variablename == 'air_filter_differential_pressure_2') {
            this.objAll.pressure2 = item.realtimevalue
          }
          if (item.variablename == 'air_inlet_pressure') {
            this.objAll.inlet = item.realtimevalue
          }
          if (item.variablename == 'air_inlet_temperature') {
            this.objAll.airtemperature = item.realtimevalue
          }
          if (item.variablename == 'boost_pressure') {
            this.objAll.boost = item.realtimevalue
          }
          // if (item.variablename == 'intake_manifold_1_temperature') {
          //   this.objAll.manifold1 = item.realtimevalue //
          //   this.leftList[1].value = item.realtimevalue
          //   this.leftList[1].max = Number(item.valuemax)
          //   this.leftList[1].rote = this.perceCalc(Number(item.valuemin),Number(item.valuemax),Number(item.realtimevalue))
          // }
          // if (item.variablename == 'intake_manifold_2_temperature') {
             
          //   this.objAll.manifold2 = item.realtimevalue 
            
          //   this.leftList[2].value = item.realtimevalue
            
          //   this.leftList[2].max = Number(item.valuemax)
            
          //   this.leftList[2].rote = this.perceCalc(Number(item.valuemin),Number(item.valuemax),Number(item.realtimevalue))
            
          // }
          if (item.variablename == 'crankcase_pressure') {
            this.objAll.crankcase = item.realtimevalue
          }
          if (item.variablename == 'oil_filter_differential_pressure') {
            this.objAll.differential = item.realtimevalue //2
            this.objAll.fuelfilter = item.realtimevalue //
            this.rightList[0].value = Math.round(item.realtimevalue)
            this.rightList[0].max = Number(item.valuemax)
            this.rightList[0].rote = this.perceCalc(Number(item.valuemin),Number(item.valuemax),Number(item.realtimevalue))
          }
          if (item.variablename == 'oil_pressure') {
            this.objAll.oilpressure = item.realtimevalue
          }
          if (item.variablename == 'oil_temperature') {
            this.objAll.oiltemperature = item.realtimevalue
          }
          if (item.variablename == 'fuel_filter_differential_pressure') {
            this.objAll.fuelfilter = item.realtimevalue //
            this.leftList[0].value = item.realtimevalue
            this.leftList[0].max = Number(item.valuemax)
            this.leftList[0].rote = this.perceCalc(Number(item.valuemin),Number(item.valuemax),Number(item.realtimevalue))
          }

          if (item.variablename == 'fuel_pressure') {
            this.objAll.fuelpressure = item.realtimevalue
          }
          if (item.variablename == 'fuel_temperature') {
            this.objAll.fueltemperature = item.realtimevalue
          }
          if (item.variablename == 'exhaust_gas_temperature') {
           
            this.objAll.gas = item.realtimevalue
          }
          if (item.variablename == 'right_manifold_exhaust_gas_temperature') {
            this.objAll.manifold3 = item.realtimevalue //
            this.rightList[1].value = Math.round(item.realtimevalue)
            this.rightList[1].max = Number(item.valuemax)
            this.rightList[1].rote = this.perceCalc(Number(item.valuemin),Number(item.valuemax),Number(item.realtimevalue))

          }
          if (item.variablename == 'left_manifold_exhaust_gas_temperature') {
            this.objAll.manifold4 = item.realtimevalue //
            this.rightList[2].value = Math.round(item.realtimevalue)
            this.rightList[2].max = Number(item.valuemax)
            this.rightList[2].rote = this.perceCalc(Number(item.valuemin),Number(item.valuemax),Number(item.realtimevalue))
          }
        });
        let vm = this
        setTimeout(() => {
          
          console.log(vm.objAll.oilpressure)
          vm.init()
        }, 1);
      });
    },
    initEchart() {
      let chartInit = echarts.init(document.getElementById('chart1'));
      var option = {
        animation: false,
        layoutAnimation: false,
        color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
          {
            offset: 0,
            color: 'rgba(247, 209, 100, 1)',
          },
          {
            offset: 0.5,
            color: 'rgba(247, 209, 100, 0.5)',
          },
          {
            offset: 1,
            color: 'rgba(247, 209, 100, 1)',
          },
        ]),
        tooltip: {
          trigger: "item",
        },
        angleAxis: {
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        radiusAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          z: 10,
        },
        polar: [
          {
            center: ["50%", "60%"],
            radius: "80%",
          },
        ],
        series: [
          // 外背景
          {
            animation: false,
            type: "gauge",
            radius: "80%",
            center: ["50%", "60%"],
            splitNumber: 10,
            silent: true,
            progress: {
              show: false,
            },
            // 仪表盘指针
            pointer: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 5,
                color: [[1, '#fff']],
                shadowColor: '#000',
                shadowBlur: 10
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              distance: -5,
              length: 5,
              lineStyle: {
                color: '#ccc',
                width: 1,
                shadowColor: '#000',
                shadowBlur: 1
              },
            },
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 100,
              },
            ],
          },
          // 内背景
          {
            animation: false,
            type: "gauge",
            radius: "70%",
            center: ["50%", "60%"],
            splitNumber: 100,
            silent: true,
            progress: {
              show: false,
            },
            // 仪表盘指针
            pointer: {
              show: false,
            },
            // 仪表盘轴线相关配置
            axisLine: {
              show: true,
              lineStyle: {
                width: 3,
                color: [[1, '#ccc']],
              },
            },
            // 刻度样式
            axisTick: {
              show: false,
            },
            // 分隔线样式
            splitLine: {
              distance: -3,
              length: 3,
              lineStyle: {
                color: '#fff',
                width: 1,
              },
            },
            // 刻度标签
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 100,
              },
            ],
          },
          // 内背景
          {
            animation: false,
            type: "gauge",
            radius: "80%",
            center: ["50%", "60%"],
            silent: true,
            min: 0,
            max: Number(this.objAll.speedMax1) ? Number(this.objAll.speedMax1) : 1,//speedMax1
            // 展示当前进度
            progress: {
              show: false,
            },
            // 仪表盘指针
            pointer: {
              show: false,
            },
            // 仪表盘轴线相关配置
            axisLine: {
              show: false,
            },
            // 刻度样式
            axisTick: {
              show: true,
            },
            // 分隔线样式
            splitLine: {
              show: true,
              lineStyle: {
                color: '#000',
                width: 2,
              },
            },
            // 刻度标签
            axisLabel: {
              show: true,
            },
            anchor: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 100,
              },
            ],
          },
          // 内容
          {
            title: {
              offsetCenter: [0, '120%']
            },
            name: "机油压力(kPa)",
            type: "gauge",
            radius: "47%",
            center: ["50%", "60%"],
            min: 0,
            max: Number(this.objAll.speedMax1) ? Number(this.objAll.speedMax1) : 1,//speedMax1
            zlevel: 10,
            // 展示当前进度
            progress: {
              show: true,
              width: 30,
              roundCap: false,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 1, 0, [{
                  offset: 0.1,
                  color: "rgba(247, 209, 100, 0)"
                },
                {
                  offset: 0.6,
                  color: "rgba(247, 209, 100, 0.5)"
                },
                {
                  offset: 1,
                  color: "rgba(247, 209, 100, 1)"
                }
                ])
              },
            },
            // 仪表盘指针
            pointer: {
              show: true,
              length: "100%",
              width: 4,
            },
            // 仪表盘轴线相关配置
            axisLine: {
              show: false,
            },
            // 刻度样式
            axisTick: {
              show: false,
            },
            // 分隔线样式
            splitLine: {
              show: false,
            },
            // 刻度标签
            axisLabel: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              borderRadius: 8,
              fontSize: 30,
              fontWeight: "bolder",
              formatter: "{value}",
              color: "rgba(0,0,0,.65)",
              fontFamily: "ABBvoice_WCNSG_Rg",
            },
            data: [
              {
                value: Number(this.objAll.oilpressure),
                name: '机油压力\n(kPa)'
              },
            ],
          },
        ],
      };
      setTimeout(() => { chartInit.resize() }, 700)
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    },
    initEchart2() {
      let chartInit = echarts.init(document.getElementById('chart2'));
      var option = {
        animation: false,
        layoutAnimation: false,
        color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
          {
            offset: 0,
            color: 'rgba(247, 209, 100, 1)',
          },
          {
            offset: 0.5,
            color: 'rgba(247, 209, 100, 0.5)',
          },
          {
            offset: 1,
            color: 'rgba(247, 209, 100, 1)',
          },
        ]),
        tooltip: {
          trigger: "item",
        },
        angleAxis: {
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        radiusAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          z: 10,
        },
        polar: [
          {
            center: ["50%", "60%"],
            radius: "80%",
          },
        ],
        series: [
          // 外背景
          {
            animation: false,
            type: "gauge",
            radius: "80%",
            center: ["50%", "60%"],
            splitNumber: 10,
            silent: true,
            progress: {
              show: false,
            },
            // 仪表盘指针
            pointer: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 5,
                color: [[1, '#fff']],
                shadowColor: '#000',
                shadowBlur: 10
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              distance: -5,
              length: 5,
              lineStyle: {
                color: '#ccc',
                width: 1,
                shadowColor: '#000',
                shadowBlur: 1
              },
            },
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 100,
              },
            ],
          },
          // 内背景
          {
            animation: false,
            type: "gauge",
            radius: "70%",
            center: ["50%", "60%"],
            splitNumber: 100,
            silent: true,
            progress: {
              show: false,
            },
            // 仪表盘指针
            pointer: {
              show: false,
            },
            // 仪表盘轴线相关配置
            axisLine: {
              show: true,
              lineStyle: {
                width: 3,
                color: [[1, '#ccc']],
              },
            },
            // 刻度样式
            axisTick: {
              show: false,
            },
            // 分隔线样式
            splitLine: {
              distance: -3,
              length: 3,
              lineStyle: {
                color: '#fff',
                width: 1,
              },
            },
            // 刻度标签
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 100,
              },
            ],
          },
          // 内背景
          {
            animation: false,
            type: "gauge",
            radius: "80%",
            center: ["50%", "60%"],
            silent: true,
            min: 0,
            max: Number(this.objAll.speedMax2) ? Number(this.objAll.speedMax2) : 1,//speedMax2
            // 展示当前进度
            progress: {
              show: false,
            },
            // 仪表盘指针
            pointer: {
              show: false,
            },
            // 仪表盘轴线相关配置
            axisLine: {
              show: false,
            },
            // 刻度样式
            axisTick: {
              show: true,
            },
            // 分隔线样式
            splitLine: {
              show: true,
              lineStyle: {
                color: '#000',
                width: 2,
              },
            },
            // 刻度标签
            axisLabel: {
              show: true,
            },
            anchor: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 100,
              },
            ],
          },
          // 内容
          {
            title: {
              offsetCenter: [0, '120%'],
            },
            name: "冷却液温度(°C)",
            type: "gauge",
            radius: "47%",
            center: ["50%", "60%"],
            min: 0,
            max: Number(this.objAll.speedMax2) ? Number(this.objAll.speedMax2) : 1,//speedMax2
            zlevel: 10,
            // 展示当前进度
            progress: {
              show: true,
              width: 30,
              roundCap: false,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 1, 0, [{
                  offset: 0.1,
                  color: "rgba(247, 209, 100, 0)"
                },
                {
                  offset: 0.6,
                  color: "rgba(247, 209, 100, 0.5)"
                },
                {
                  offset: 1,
                  color: "rgba(247, 209, 100, 1)"
                }
                ])
              },
            },
            // 仪表盘指针
            pointer: {
              show: true,
              length: "100%",
              width: 4,
            },
            // 仪表盘轴线相关配置
            axisLine: {
              show: false,
            },
            // 刻度样式
            axisTick: {
              show: false,
            },
            // 分隔线样式
            splitLine: {
              show: false,
            },
            // 刻度标签
            axisLabel: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              borderRadius: 8,
              fontSize: 30,
              fontWeight: "bolder",
              formatter: "{value}",
              color: "rgba(0,0,0,.65)",
              fontFamily: "ABBvoice_WCNSG_Rg",
            },
            data: [
              {
                value: Number(this.objAll.temperature),
                name: '冷却液温度(°C)'
              },
            ],
          },
        ],
      };
      setTimeout(() => { chartInit.resize() }, 700)
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    },
    initEchartZ() {
      let chartInit = echarts.init(document.getElementById('chartCenter'));
      var option = {
        animation: false,
        layoutAnimation: false,
        color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
          {
            offset: 0,
            color: 'rgba(247, 209, 100, 1)',
          },
          {
            offset: 0.5,
            color: 'rgba(247, 209, 100, 0.5)',
          },
          {
            offset: 1,
            color: 'rgba(247, 209, 100, 1)',
          },
        ]),
        tooltip: {
          trigger: "item",
        },
        angleAxis: {
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        radiusAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          z: 10,
        },
        polar: [
          {
            center: ["50%", "45%"],
            radius: "90%",
          },
        ],
        series: [
          // 外背景
          {
            animation: false,
            type: "gauge",
            radius: "90%",
            center: ["50%", "45%"],
            splitNumber: 10,
            silent: true,
            progress: {
              show: false,
            },
            // 仪表盘指针
            pointer: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 5,
                color: [[1, '#fff']],
                shadowColor: '#000',
                shadowBlur: 10
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              distance: -5,
              length: 5,
              lineStyle: {
                color: '#ccc',
                width: 1,
                shadowColor: '#000',
                shadowBlur: 1
              },
            },
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 100,
              },
            ],
          },
          // 内背景
          {
            animation: false,
            type: "gauge",
            radius: "80%",
            center: ["50%", "45%"],
            splitNumber: 100,
            silent: true,
            progress: {
              show: false,
            },
            // 仪表盘指针
            pointer: {
              show: false,
            },
            // 仪表盘轴线相关配置
            axisLine: {
              show: true,
              lineStyle: {
                width: 3,
                color: [[1, '#ccc']],
              },
            },
            // 刻度样式
            axisTick: {
              show: false,
            },
            // 分隔线样式
            splitLine: {
              distance: -3,
              length: 3,
              lineStyle: {
                color: '#fff',
                width: 1,
              },
            },
            // 刻度标签
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 100,
              },
            ],
          },
          // 内背景
          {
            animation: false,
            type: "gauge",
            radius: "90%",
            center: ["50%", "45%"],
            silent: true,
            min: 0,
            max: Number(this.objAll.speedMax) ? Number(this.objAll.speedMax) : 1,//speedMax
            // 展示当前进度
            progress: {
              show: false,
            },
            // 仪表盘指针
            pointer: {
              show: false,
            },
            // 仪表盘轴线相关配置
            axisLine: {
              show: false,
            },
            // 刻度样式
            axisTick: {
              show: true,
            },
            // 分隔线样式
            splitLine: {
              show: true,
              lineStyle: {
                color: '#000',
                width: 2,
              },
            },
            // 刻度标签
            axisLabel: {
              show: true,
            },
            anchor: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 100,
              },
            ],
          },
          // 内容
          {
            title: {
              offsetCenter: [0, '120%']
            },
            name: "发动机转速(rpm)",
            type: "gauge",
            radius: "50%",
            center: ["50%", "45%"],
            min: 0,
            max: Number(this.objAll.speedMax) ? Number(this.objAll.speedMax) : 1,//speedMax
            zlevel: 10,
            // 展示当前进度
            progress: {
              show: true,
              width: 30,
              roundCap: false,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 1, 0, [{
                  offset: 0.1,
                  color: "rgba(247, 209, 100, 0)"
                },
                {
                  offset: 0.6,
                  color: "rgba(247, 209, 100, 0.5)"
                },
                {
                  offset: 1,
                  color: "rgba(247, 209, 100, 1)"
                }
                ])
              },
            },
            // 仪表盘指针
            pointer: {
              show: true,
              length: "100%",
              width: 5,
            },
            // 仪表盘轴线相关配置
            axisLine: {
              show: false,
            },
            // 刻度样式
            axisTick: {
              show: false,
            },
            // 分隔线样式
            splitLine: {
              show: false,
            },
            // 刻度标签
            axisLabel: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              borderRadius: 8,
              fontSize: 30,
              fontWeight: "bolder",
              formatter: "{value}",
              color: "rgb(249,189,8)",
              fontFamily: "ABBvoice_WCNSG_Rg",
            },
            data: [
              {
                value: Math.round(Number(this.objAll.speed)),
                name: '发动机转速(rpm)'
              },
            ],
          },
        ],
      };
      setTimeout(() => { chartInit.resize() }, 700)
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    },
    
  },
  mounted() {
    let vm = this
    setTimeout(() => {
      vm.getData()
    }, 10);
    
  },
};
</script>
          
<style lang="less" scoped>
.information {
  background: #fff;
  padding: 20px;
  height: 989px;

  .title {
    font-size: 16px;
    font-weight: 600;
    color: #1f1f1f;
    border-left: 4px solid #FF8C00;
    padding-left: 10px;
    display: flex;
    line-height: 15px;
  }

  .btnSite {
    display: flex;
    margin: 20px 0;
  }

  .ulBtn {
    display: flex;
    background: rgb(245, 245, 245);
    border-radius: 5px;

    &>div {
      display: flex;

      &>span {
        display: inline-block;
        padding: 0 5px;
        margin: 3px;
        border-radius: 3px;
        cursor: pointer;
        line-height: 24px
      }
    }
  }

  .information-content {
    flex: 1;
    display: flex;
    width: 100%;
    background: rgb(245, 246, 247);

    #chart1 {
      width: 25%;
    }

    #chart2 {
      width: 25%;
    }

    #chartCenter {
      width: 50%;
    }

    &>div {
      // width: 25%;
      min-height: 493px
    }

    // &>div:nth-child(2) {
    //   width: 50%;
    // }
  }

  .information-bottom {
    width: 100%;
    display: flex;
    margin: 40px 0 30px 0;

    &>div {
      flex: 1;
    }

    &>div:nth-child(2) {
      color: black;
      background: rgb(249, 249, 249);
      margin: 0 20px;
    }

    &>div:nth-child(1),
    &>div:nth-child(3) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .leftRight {
      &>div {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin: 3px 0;

        .div1 {
          display: flex;
          justify-content: space-between;
          color: #1F1F1F;
          font-weight: 600;
          font-size: 13px;
        }

        .div2 {
          width: 100%;
          display: flex;
          background: #EDEDED;
          margin: 10px 0;
          height: 10px;
        }

        .div3 {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .center {
      display: flex;
      border: 1px solid rgb(241, 241, 241);

      &>ul {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        margin: 0 24px;

        &>li {
          flex: 1;
          display: flex;
          justify-content: space-between;
          padding: 20px 0;
          border-bottom: 1px dashed #e9e7e7;

          &>div:nth-child(2) {
            color: #000;
            font-size: 20px;
            font-weight: 600;
          }
        }

        &>li:last-child {
          border: 0
        }
      }
    }
  }

  .centerSite {
    margin: 20px 0;
    height: 850px;
    overflow-y: scroll;

    .center_news {
      .name_line {
        display: flex;
        align-items: center;
        color:  #aeb1b5;
        margin: 0 10px;

        &>div {
          margin-left: 16px;
          flex: 1;
          height: 1px;
          transform: scaleY(0.5);
          border-top: solid rgb(235, 235, 235);
        }
      }

      &>ul {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);

        &>li {
          height: 90px;
          display: flex;
          flex-direction: column;
          background: rgb(249, 249, 249);
          margin: 10px;
          padding: 10px;

          &>span {
            display: inline-block;
            flex: 1;
            color: #000;
          }

          &>span:nth-child(2) {
            font-size: 27px;
            font-weight: 600;
          }
        }
      }
    }

  }
}
</style>