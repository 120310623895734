import { render, staticRenderFns } from "./deviceModel.vue?vue&type=template&id=077c8b45&scoped=true"
import script from "./deviceModel.vue?vue&type=script&lang=js"
export * from "./deviceModel.vue?vue&type=script&lang=js"
import style0 from "./deviceModel.vue?vue&type=style&index=0&id=077c8b45&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "077c8b45",
  null
  
)

export default component.exports