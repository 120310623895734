<template>
    <!-- 选择模块 -->
    <el-dialog title="菜单信息" custom-class="quickmenu" :visible.sync="dialogVisible" :append-to-body="true" :modal-append-to-body="true" width="684px">
        <!-- 表格渲染 -->
        <el-tree ref="tree" :data="MenuData" :check-strictly="false" show-checkbox   node-key="code" :props="resourceProps" @check-change="handleCheckChange">
        </el-tree>
        <!-- 操作按钮 -->
        <div class="quickmenu-bottom" slot="footer">
            <el-button class="quickmenu-bottom-up" @click="dialogVisible = false">取 消</el-button>
            <el-button class="quickmenu-bottom-down" @click="defineChooseBtn">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "quickmenu",
    props:['selectcode'],
    data() {
        return {
            dialogVisible: false,
            resourceProps: {
                children: "children",
                label: "name",
				disabled (data, node) { //方案三、若需要算法建议使用func,return一个boolean值
				return data.disable === '1'
				}
            },
            currentSelectData:[],
            isShowTree: false,
			MenuData:[],
			selectdatacodeList:[]
        }
    },
    computed:{
        currentuserinfo(){
            return this.$store.state.currentuserinfo
        }
    },
    methods:{
        handleCheckChange(data, checked){
            // if (checked) {
				// var index=this.currentSelectData.findIndex(item=>item.code==data.code)
				// if(index==-1){
				// 	this.currentSelectData.push(data)
				// 	this.selectdatacodeList.push(data.code)
				// }else{
				// 	this.currentSelectData.splice(index,1)
				// 	this.selectdatacodeList.splice(index,1)
				// }
                // this.$refs.tree.setCheckedKeys(this.selectdatacodeList)
            // };
        },
        view(){
			this.dialogVisible = true;
			this.selectdatacodeList=[]
			this.$nextTick(()=>{
				this.$refs.tree.setCheckedKeys([]);
				this.getMenuData();
			})
        },

        //确认选择
        defineChooseBtn(){
				if(this.$refs.tree.getCheckedKeys().length==0){
					this.$message.warning("请先选择数据")
					return
				}
				console.log(this.$refs.tree.getCheckedKeys(),"获取选中的节点")
                this.$emit("sendquickmenuData",this.$refs.tree.getCheckedKeys())
                this.dialogVisible = false;
        },

		getMenuData(){
			var url=Config.dev_url+"/api-user/menus/findMenuTree?rolecode="+this.currentuserinfo.rolecode
            this.$axios.get(url).then(res=>{
					this.MenuData=res.data
					// this.MenuData.forEach(item => {
					// 	if(item.selectedbydefault=='1'){
					// 		this.selectdatacodeList.push(item.code)
					// 	}
					// });
                    this.addselect(this.MenuData)
						this.$refs.tree.setCheckedKeys(this.selectdatacodeList)
            })
		},

        // 将已选择的全部勾选上
        addselect(arr){
            arr.forEach(item => {
                if(item.selectedbydefault=='1'){
                    this.selectdatacodeList.push(item.code)
                }
                if(item.children!=null){
                    this.addselect(item.children)
                }
            });
        }
    }
}
</script>

<style lang="less">
.quickmenu{
    border-radius: 4px;
  .el-tree-node__expand-icon.is-leaf{
    margin-right: 2px;
  }
    .el-dialog__header{
        padding: 16px 24px;
        background: #f3f4f5;
        border-radius: 4px 4px 0px 0px;
        .el-dialog__title{
            font-size: 18px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 600;
            color: #202328;
            line-height: 24px
        }
        .el-dialog__close{
            color: #646a74;
        }
    }
    .el-dialog__body{
        max-height: 45vh;
        padding: 16px;
        overflow: auto;
        .el-tree{
            .el-tree-node{
                border-radius: 3px;
                margin-bottom: 4px;
                .el-tree-node__content{
                    height: 36px;
                    border-radius: 3px;
                    .el-tree-node__expand-icon:not(.is-leaf){
                        color: #000000;
                        font-size: 14px;
                    }
                    .el-checkbox{
                        &.is-checked{
                            .el-checkbox__inner{
                                background-color: var(--theme-mode-bgcolor);
                                border-color: var(--theme-mode-bgcolor);
                            }
                        }
                        .is-indeterminate{
                            .el-checkbox__inner{
                                background-color: var(--theme-mode-bgcolor);
                                border-color: var(--theme-mode-bgcolor);
                            }
                        }
                    }
                }
                .el-tree-node__content:not(.is-expanded):hover{
                    background: #f1f2f5;
                    .custom-tree-node{
                        .custom-tree-node-operation{
                            display: block;
                            i:hover{
                                background: #ffffff;

                            }
                        }
                    }
                }
                &.is-current:not(.is-expanded),&.is-current:not(.is-expanded):hover{
                    background: var(--theme-mode-bgcolor);
                    .el-tree-node__content{
                        background: var(--theme-mode-bgcolor);
                        .el-tree-node__expand-icon:not(.is-leaf){
                            color: rgba(255,255,255,0.90);
                        }
                    }
                    .el-tree-node__children{
                        background: #FFFFFF;
                        .el-tree-node{
                            background: #FFFFFF;
                            .el-tree-node__content{
                                background: #FFFFFF;
                                .el-tree-node__expand-icon:not(.is-leaf){
                                    color: #000000;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .el-dialog__footer{
        padding: 12px 24px;
        .el-button{
            width: 65px;
            height: 32px;
            background: var(--theme-mode-bgcolor);
            border-radius: 3px;
            line-height: 32px;
            padding: 0px;
            border: 1px solid var(--theme-mode-bgcolor);
            span{
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: #fff;
            }
            .el-icon-arrow-left{
                margin-right: 4px;
            }
            .el-icon-arrow-right{
                margin-left: 4px;
            }
        }
        .quickmenu-bottom-up{
            background: none;
            background: #ffffff;
            border-color: #dee0e3;
            span{
                color: #646a74;
            }
            i:hover{
                color: #646a74;
            }
        }
        .quickmenu-bottom-up:hover{
            background: #ffffff;
            border-color: #dee0e3;
        }
        .quickmenu-bottom-down.is-disabled{
            opacity: 0.5;
            background: var(--theme-mode-bgcolor);
        }
    }
}
.quickmenu-searchBtn,.quickmenu-searchBtn:focus,.quickmenu-searchBtn:hover,.quickmenu-resetBtn{
    width: 65px;
    height: 32px;
    background: var(--theme-mode-bgcolor);
    border-radius: 3px;
    line-height: 30px;
    padding: 0px;
    border: 1px solid var(--theme-mode-bgcolor);
    span{
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #fff;
    }
}
.quickmenu-resetBtn,.quickmenu-resetBtn:focus,.quickmenu-resetBtn:hover{
    background: #ffffff;
    border-color: #dee0e3;
    span{
        color: #646a74;
    }
}
</style>