<template>
  <div class="deviceDetails">
    <div id="maintablepage">
      <div class="maintablepage-content">
        <!-- 内容 -->
        <div class="contant_O">
         
          <div class="cont_z">
            <div style="width: 100%;display: flex;margin-bottom: 20px; justify-content: space-between;">
              <div class="chcickIndex">
                <div v-for="(item1, index1) in searchObjlist" :key="index1" style="display: flex;">
                  <div style="width: 96px;">
                    {{ item1.nameSite }}:
                  </div>
                  <el-checkbox-group v-model="checkList" class="time_group">
                    <el-checkbox v-for="(item, index) in item1.list" :key="index" :label="item.code">{{ item.name
                    }}</el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="time-right" style="position: absolute;right: 17px;top: 10px;">
                <div class="type-group">
                  <span class="pick-type" v-for="(item, idx) in timetType" :key="idx" @click="changeType(item.code)">
                    <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
                      item.name
                    }}</font>
                  </span>
                </div>
                <el-date-picker type="date" v-if="searchObj.TYPE == '按天'" v-model="searchObj.VALUE" placeholder="选择天"
                  :clearable="false">
                </el-date-picker>
                <el-date-picker type="month" v-if="searchObj.TYPE == '按月'" v-model="searchObj.VALUE" placeholder="选择月"
                  :clearable="false">
                </el-date-picker>
                <el-date-picker type="year" v-if="searchObj.TYPE == '按年'" v-model="searchObj.VALUE" placeholder="选择年"
                  :clearable="false">
                </el-date-picker>
              </div>
            </div>
            <div class="echarts" id="echartsZ"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts"
export default {
  data() {
    return {
      checkList: [],
      searchObjlist: [
        {
          nameSite: '运行基本信息',
          list: [
            {
              name: "负载率",
              code: 'load'
            },
            {
              name: "主油门位置",
              code: 'primary_throttle_position'
            },
            {
              name: "系统电压",
              code: 'battery_voltage'
            }, 
            // {
            //   name: "期望发动机转速",
            //   code: 'desired_engine_speed'
            // },
            {
              name: "发动机运行小时",
              code: 'engine_hours'
            },
            
            {
              name: "发动机转速",
              code: 'engine_speed'
            },
            {
              name: "瞬时燃油消耗率",
              code: 'fuel_rate'
            },
            {
              name: "总燃油消耗",
              code: 'fuel_burned'
            },
          ]
        },
        {
          nameSite: '冷却系统',
          list: [
            {
              name: "后冷却器冷却液温度",
              code: 'aftercooler_coolant_temperature'
            },
            {
              name: "冷却液温度",
              code: 'coolant_temperature'
            }
          ]
        },
        {
          nameSite: '进气系统',
          list: [
            // {
            //   name: "空气切断阀状态",
            //   code: 'air_shutoff_status'
            // },
            {
              name: "1#空气过滤器压差",
              code: 'air_filter_differential_pressure_1'
            },
            {
              name: "2#空气过滤器压差",
              code: 'air_filter_differential_pressure_2'
            },
            // {
            //   name: "进气压力",
            //   code: 'air_inlet_pressure'
            // },
            {
              name: "进气温度",
              code: 'air_inlet_temperature'
            },
            {
              name: "增压器压力",
              code: 'boost_pressure'
            },
            // {
            //   name: "1#进气歧管温度",
            //   code: 'intake_manifold_1_temperature'
            // },
            // {
            //   name: "2#进气歧管温度",
            //   code: 'intake_manifold_2_temperature'
            // }
          ]
        },
        {
          nameSite: '燃油系统',
          list: [
            {
              name: "燃油过滤器压差",
              code: 'fuel_filter_differential_pressure'
            },
            {
              name: "燃油压力",
              code: 'fuel_pressure'
            },
            // {
            //   name: "燃油温度",
            //   code: 'fuel_temperature'
            // }
          ]
        },
        {
          nameSite: '排气系统',
          list: [
            // {
            //   name: "排气温度",
            //   code: 'exhaust_gas_temperature'
            // },
            {
              name: "歧管排气温度(R)",
              code: 'right_manifold_exhaust_gas_temperature'
            },
            {
              name: "歧管排气温度(L)",
              code: 'left_manifold_exhaust_gas_temperature'
            }
          ]
        },
        {
          nameSite: '润滑油系统',
          list: [
            {
              name: "曲轴箱压力",
              code: 'crankcase_pressure'
            },
            {
              name: "机油过滤器压差",
              code: 'oil_filter_differential_pressure'
            },
            {
              name: "机油压力",
              code: 'oil_pressure'
            },
            // {
            //   name: "机油温度",
            //   code: 'oil_temperature'
            // }
          ]
        }
      ],
      searchObj: {
        TYPE: "按天",
        VALUE: this.$moment()
      },
      timetType: [
        {
          code: "按天",
          name: "按天",
        },
        {
          code: "按月",
          name: "按月",
        },
        {
          code: "按年",
          name: "按年",
        },
      ],
      dataAll: {},
      chart: {}
    }
  },
  created() {
  },
  activated() {
    window.addEventListener("resize", this.chartInit);
  },
  mounted() {
    let echart = document.getElementById("pane-状态监测")
    this.resizeEcharts(echart)

    try{
      this.getrowData()
    }catch{}
    
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.chartInit);
  },
  watch: {
    'searchObj.VALUE': {
      handler: function () {
        this.getrowData();
      },
      deep: true,
      immediate: true
    },
    'checkList': {
      handler: function (val) {
        if (val.length > 6) {
          this.checkList.shift();
        }
        this.getrowData();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    resizeEcharts(echart) {
      let vm = this
      const elementResizeDetectorMaker = require('element-resize-detector')// 引入监听dom变化的组件
      const erd = elementResizeDetectorMaker()
      // 监听id为echart的元素 大小变化
      erd.listenTo(echart, function (element) {

        vm.chartInit()
      })
    },
    chartInit() {

      this.chart = echarts.init(document.getElementById('echartsZ'));
      var colorList = ["#2D76FE", "#4FDE92", "#3E6DFF", "#536EBF", "#6E93FF", "#5FEBFF", "#8AF1FF"];
      var categoryData = this.dataAll.categoryData || []
      var arrayObj = this.dataAll.seriesData || []
      let seriesArr = []
      let yAxisArr = []
      if (arrayObj.length == 0) {
        yAxisArr = [];
        seriesArr = [];
        yAxisArr.push(
          {
            type: 'value',
            alignTicks: true,
            axisLine: {
              show: true,
            },
            axisLabel: {
              formatter: '{value}'
            }
          }
        )
        seriesArr.push(
          {
            type: "line",
            data: [],
          }
        )
      } else {
        yAxisArr = [];
        seriesArr = [];
        arrayObj.forEach((item, index) => {
          console.log(index)
          if (index % 2) {
            let offsetSite = 40
            yAxisArr.push(
              {
                type: 'value',
                name: item.name,
                nameRotate:90,
                nameTextStyle: {
                    padding:[0, 0, 0, 0],
                    color: "black"
                },
                position: 'right',
                alignTicks: true,
                offset: index * offsetSite - offsetSite,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: colorList[index]
                  }
                },
                axisLabel: {
                  formatter: '{value}'
                }
              }
            );
            seriesArr.push({
              type: "line",
              name: item.name,
              data: item.values,
              itemStyle: {
                color: colorList[index]
              },
              yAxisIndex: index,
            })
          } else {
            let offsetSite = 40
            
            yAxisArr.push(
              {
                type: 'value',
                name: item.name,
                nameRotate:-90,
                position: 'left',
                nameTextStyle: {
                    padding:[-500, -10, 0, 0],
                    color: "black"
                },
                
                alignTicks: true,
                offset: offsetSite * index,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: colorList[index]
                  }
                },
                axisLabel: {
                  formatter: '{value}'
                }
              }
            );
            seriesArr.push({
              type: "line",
              name: item.name,
              data: item.values,
              itemStyle: {
                color: colorList[index]
              },
              yAxisIndex: index,
            })
          }
        });
      }

      var option = {
        animation: true,
        backgroundColor: 'white',
        layoutAnimation: false,
        grid: {
          left: "10%",
          right: "10%",
          bottom: "15%",
          top: "25%",
          containLabel: true,
        },
        legend: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            height: 20,
            bottom: 0,
          },
          {
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ],
        xAxis: {
          type: "category",
          data: categoryData,
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
        },
        yAxis: yAxisArr,
        series: seriesArr,
      }

      this.chart.clear()
      this.chart.setOption(option);
      this.chart.resize()

    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getrowData()
    },
    getrowData() {
      let type = 'day', startTime = '', endTime = ''
      if (this.searchObj.TYPE == '按天') {
        type = 'day15m'
        startTime = this.$moment(this.searchObj.VALUE).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        endTime = this.$moment(this.searchObj.VALUE).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else if (this.searchObj.TYPE == '按月') {
        type = 'month'
        startTime = this.$moment(this.searchObj.VALUE).startOf('month').format('YYYY-MM-DD HH:mm:ss')
        endTime = this.$moment(this.searchObj.VALUE).endOf('month').format('YYYY-MM-DD HH:mm:ss')
      }
      else if (this.searchObj.TYPE == '按年') {
        type = 'year'
        startTime = this.$moment(this.searchObj.VALUE).startOf('year').format('YYYY-MM-DD HH:mm:ss')
        endTime = this.$moment(this.searchObj.VALUE).endOf('year').format('YYYY-MM-DD HH:mm:ss')
      }
      let data = {
        "queryType": type,
        "assetNum": this.$store.state.ASSET.detailInfos.ASSETNUMBER,
        "variableName": this.checkList,
        "startTime": startTime,
        "endTime": endTime
      }
      try{
      let url = Config.dev_url + "/api-apps-v2/api/v2/equipment/telemetry/query"
      this.$axios.post(url, data).then((res) => {
        if (res.data==undefined){
          return
        }
        if (res.data.code == 0) {
          this.$nextTick(() => {
            this.dataAll = res.data.data;
            this.chartInit()
          })
        } else {
          this.$message(res.data.msg)
        }
      });
    }catch(e){
      console.log(e)
    }
    },
  }
}
</script>
<style lang="less">
.deviceDetails .isactive-type2 {
  color: #F9BA08 !important;
}

.row-column {
  display: flex;
  flex-direction: column;
  background: white;
}



.deviceDetails {
  background: #fff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
}

.deviceDetails #maintablepage {
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#maintablepage .maintablepage-content .maintablepage-header {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#maintablepage .maintablepage-content {
  flex: auto;
  overflow-x: auto;
  overflow-y: auto;
}

.app-table .el-table__header thead tr th {
  padding: 0;
  height: 40px;
  background: #f2f3f5;
}
</style>
<style lang="less" scoped>
#echartsZ {
  width: 100%;

  height: 500px;
  margin: auto;
  margin-bottom: 10px;
}

.msrStr {
  font-weight: bold;
  font-size: 16px;
  border-left: 4px solid #FF8C00;
  padding-left: 10px;
  color: #000;
  line-height: 20px;
  position: absolute;
  top: 20px;
}

.contant_O {
  overflow-y: scroll;
}

.cont_z {
  
}

.chcickIndex {
  width: 98%;
  margin-top: 13px;
}

.time_group {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.time-right {
  display: flex;
  align-items: baseline;
  margin-right: 160px;
}

.deviceDetails .pick-type {
  margin: 0 20px;
  cursor: pointer;

  .isactive-type {
    color: #FF8C00 !important;
  }
}

#maintablepage {
  height:fit-content !important;
}

/deep/.el-input__inner {
  height: 32px !important;
}

/deep/.el-input__icon {
  line-height: 32px !important;
}


/deep/.is-checked {
  .el-checkbox__inner {
    border-color: #FF8C00 !important;
    background: #FF8C00 !important;
  }
}

/deep/.el-checkbox__label {
  color: #000 !important;
}

/deep/.el-checkbox__inner:hover {
  border-color: #FF8C00 !important;
}

/deep/.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #FF8C00 !important;
}
</style>