<template>
    <div id="navindex">
        <abb-header v-if="this.$route.query.type != 'todo'"/>
        <el-container>            
            <el-main id="vmMain" :style="{height: $route.query.type == 'todo' ? '100vh' : 'calc(100vh + 25px);'}">
                    <!-- 研发产品 -->
                <div id="productGeneral"></div>
                <router-view :key="$route.path + $route.query.now"></router-view>
            </el-main>
        </el-container>
        <!-- 客户化文件 -->
        <customizationPage/>
    </div>
</template>

<script>
import abbHeader from "@/component/custHead/custHead";
import customizationPage from "@/views/customizationPage/customizationPage";
export default {
    name: "navindex",
    components:{abbHeader,customizationPage},
    computed:{
        // 当前用户信息
        currentuserinfo() {
            return this.$store.state.currentuserinfo
        },

    },
    watch:{
        '$route.name': function(val,oldVal) {
            if(val!= 'productGeneral') {
                this.$store.commit("setcustomEventInfo",{})
            }
        },
    },
    data() {
        return {

        }
    },
    methods:{
        getAllMenu() {
            this.$store.commit("setallMenuLoading", true)
            this.$store.commit("setallMenuData", [])
            this.$store.commit("setViewMenuData", [])

            if(!!this.currentuserinfo.rolecode){
            
              var url = Config.dev_url + '/api-user/menus/findMenuByRoleCode/' + this.currentuserinfo.rolecode + "?hidden=0";
              this.$axios.get(url).then((res) => {
                if(res.data.length <=2){
                    let item = {
                        associationdesc: null,
                        associatii:null,
                        associatioe:"ASSET",
                        authRoleSigns:null,
                        canauthorizao:"0",
                        chanb:null,
                        changem:"2023-01-17 00:00:00",
                        chee:null,
                        chile:null,
                        code: "M0180",
                        createby: null,
                        createtime: "2023-01-17 00:09:33",
                        css: null,
                        delflag: "0",
                        disable: null,
                        enname: "ASSET",
                        hasChildren: null,
                        hidden: 0,
                        id: "1901",
                        isallMenus: null,
                        isforapp: 0,
                        isstandard: "0",
                        issysteminit: 0,
                        menucodes: null,
                        menutype: "0",
                        name: "资产台账",
                        pcode: "M0145",
                        pname: null,
                        roleSignList: null,
                        rolecode: null,
                        selectedbydefault: null,
                        sort: 5,
                        source: null,
                        sourcedesc: null,
                        sourceid: null,
                        subMenus: null,
                        systemed: 0,
                        url: null
                    }
                    this.$store.commit("setdefaultActiveIndex", String(item.id));
                        this.$store.commit("setCurrentSelectMenuInfo", item);
                        //向子应用传递数据
                        this.$actions.setGlobalState({
                            currentuserinfo: this.$store.state.currentuserinfo,
                            currentSelectMenuInfo: item,
                        });
                    this.$router.push({
                        path: "/productGeneral/generalApp/ASSET",
                        query: {
                            now: new Date().getTime(),
                            title: "资产台账",
                        },
                    });
                }

                this.$store.commit("setallMenuData", res.data)
                this.$store.commit("setViewMenuData", res.data)
              }).catch((err) => {
                this.$store.commit("setallMenuData", [])
                this.$store.commit("setViewMenuData", [])
              }).finally(()=>{
                this.$store.commit("setallMenuLoading", false)
              })
            }

        },
        setTheme() {
            //设置默认主题
            if(!!this.currentuserinfo.NAVSTYLE){
                document.documentElement.setAttribute("theme-mode",this.currentuserinfo.NAVSTYLE);
            }else{
                document.documentElement.setAttribute("theme-mode", "light");
            }
            if(!!this.currentuserinfo.THEMECOLOR){
                document.documentElement.setAttribute("theme-color",this.currentuserinfo.THEMECOLOR);
            }else{
                document.documentElement.setAttribute("theme-color", "#1C74F1");
            }
        },
        //获取用户信息
        getUserInfo(){
            var url = Config.dev_url + '/api-user/users/currentMap';
            this.$axios.get(url, {}).then((res) => {//获取当前用户信息
                this.$store.commit('setcurrentuserinfo', res.data);
                this.setTheme()
                this.getAllMenu()
            }).catch((err) => {
            });
        }
    },
    created() {
        if(Object.keys(this.currentuserinfo).length==0) {
            this.getUserInfo();
        } else {
            this.setTheme()
            this.getAllMenu()
        }
    },
    mounted(){

    }
}
</script>

<style lang="less">
#navindex{
    .navindex-aside{
      position: relative;
    }
}


</style>
