import { initGlobalState } from "qiankun";

const initialState = {
    //这里写初始化数据
    currentuserinfo:null,
    customEventInfo: {},//客户化信息
};

// 初始化 state
const actions = initGlobalState(initialState);

export default actions;