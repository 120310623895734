import Vue from 'vue'
import router from '../router'
import axios from 'axios'
import { Message,MessageBox } from 'element-ui';
import store from "../store";
var loading;
var flag = true;
import {message} from "@/resetMessage";
import utils
    from "../../utils";
// http request 拦截器
axios.interceptors.request.use(
  request => {
      if(!request.noToken){
          var token = sessionStorage.getItem("application_access_token");
          if (token) {
              request.headers.Authorization =  !store.state.useOtherToken ? 'Bearer ' + token : store.state.otherToken;
          }
      }
    if(store.state.currentuserinfo != null && !!store.state.currentuserinfo.rolecode){
        request.headers.roleCode = store.state.currentuserinfo.rolecode
    }
    return request;
  },
  err => {
    loading.close();
    return Promise.reject(err)
  },
)
let messageBoxFlag = 0 // 默认 MessageBox 未打开
// http response 拦截器
axios.interceptors.response.use(
  response => {
    var _this = this;
    return response;
  },
  error => {
      console.log(error.response)
      //登录页面不需要过期提示
    if (error && error.response) {
        switch (error.response.status) {
            case 401:
                // Message.error('未授权，请登录');
                if(store.state.otherToken){
                    let param = new URLSearchParams();
                    param.append("username", 'admin');
                    let pwd = utils.encrypt('1234qwer*', "hAw6eqnFLKxpsDv3");
                    let url = 'http://203.93.173.178:30030/api-auth/oauth/user/token';
                    axios({ method: "post", url: url, data: param, headers: { "client_id": "webApp", "client_secret": "webApp" } }).then((res) => {
                        if (res.data.access_token) {
                            Message.info('token重新获中,请稍后再试')
                            this.$store.commit('setOtherToken',res.data.access_token)
                        }
                    })
                }else{
                    if (messageBoxFlag === 0){
                        messageBoxFlag = 1 // 修改标记，打开 MessageBox
                        MessageBox.alert('登录超时！请重新登录！', '温馨提示', {
                            confirmButtonText: '确定',
                            showClose: false,
                            callback: action => {
                                console.log("走进了了")
                                messageBoxFlag = 0 // 修改标记，打开 MessageBox
                                window.history.pushState(null,'','/')
                                sessionStorage.clear()
                                sessionStorage.clear()
                            }
                        });
                    }
                }
                break;
            case 403:
                Message.error('拒绝访问');
                if(store.state.otherToken){
                    let param = new URLSearchParams();
                    param.append("username", 'admin');
                    let pwd = Utils.encrypt('1234qwer*', "hAw6eqnFLKxpsDv3");
                    let url = 'http://203.93.173.178:30030/api-auth/oauth/user/token';
                    axios({ method: "post", url: url, data: param, headers: { "client_id": "webApp", "client_secret": "webApp" } }).then((res) => {
                        if (res.data.access_token) {
                            Message.info('token重新获中,请稍后再试')
                            this.$store.commit('setOtherToken',res.data.access_token)
                        }
                    })
                }
                window.history.pushState(null,'','/')
                break;
            case 503:
                message.error('服务器错误503');
                break;
            case 500:
                if(error.response.data.msg == "用户名或密码错误"){
                    message.error(error.response.data.msg)
                }
                // message.error(error.response.data.msg);
                break;
        }
    }
    return Promise.reject(error.response.data);
  },

)
Vue.prototype.$axios = axios
