<template>
    <div>
      <customization ref="customization" :currentCustomEventInfo="currentCustomEventInfo" title="客户化文件"/>
    </div>
</template>
<script>
export default {
  name: 'customizationPage',
    data() {
      return {
        currentCustomEventInfo: {
          // WZTZITEM: {                        //当前客户化方法所属应用
          //   addChildData: {},                  // 新增子表默认值
          //   appDictData: {},                   // 当前应用所有数据字典信息
          //   appEncryptionData: {},             // 当前应用所有需要加密的字段信息
          //   appSignatureoptionData: {},        // 当前应用所有签名信息
          //   childTableJson: [],                // 子表json
          //   collapseNames: [],                 // 页面所有折叠面板
          //   currentAppIsHasFlow: true,         // 当前应用是否有流程
          //   currentDetailTabsModel: "详情",    // 当前详情页tab选中值
          //   currentShowPage: 2,                // 当前显示的tab页
          //   detailInfos: {
            // childChangeData: [
              // table_1640685404000_17878:[]    //修改的子表值
            // ]
          //},                   // 详情数据 包含子表数据 子表唯一标识    table_1640685404000_17878:[]
          //   detailItemJson: [],                // 详情页面基础组件json
          //   detailPageJson: {},                // 详情页面json
          //   flowBtnObj: {},                    // 流程相关信息
          //   isrequestChildTableData: false,    // 是否请求子表数据
          //   jsonData: {},                      // 应用json
          //   mainTableJson: {},                 // 当前主表json
          //   mainTablePag: {},                  // 当前主表分页条件
          //   mainTableSearchList: {},           // 主表搜索条件
          //   mainTableSelectData: [],           // 主列表选中的数据
          //   searchItemJson: [],                // 主表搜索区域的基础组件json
          //   table_1589165322_1040381848: [],   // 主表唯一标识:[主列表数据]
          //   tabsElement: [],                   // 页面所有tab的选中
          //   updatChildTableData: false,        // 刷新子表
          //   updateDetailData: false,           // 刷新详情数据
          //   updateMainTableData: false         // 刷新主表
          //   isSaveAllData: false               // //是否保存详情数据
          // },
          //   customEvent: "cnooc_toStock",      //客户化方法名
          //   from: "detailPage/buttonClickFun", //从何处获取
          //   item: {}                           //客户化按钮中的参数
          //   singleChoiceData: {}               //弹出框 单选选择的数据
        }
      }
    },
    components:{
      customization: () => import ('@/views/customizationPage/customization'),//客户化入口
    },
    computed:{
      customEventInfo() {
        return this.$store.state.customEventInfo
      },
      app() {
        var str = this.$route.params.pathMatch
        // generalApp/WZTZITEM
        var arr = str.split('/')
        return arr[1]
      },
      //当前应用信息
      currentAppInfo() {
        return this.currentCustomEventInfo[this.app]
      },
      //主表信息
      detailInfos() {
        return this.currentAppInfo.detailInfos
      },
      // 子表信息
      childTableJson() {
        return this.currentAppInfo.childTableJson
      },
      // 登录人信息
      currentuserinfo(){
        return this.$store.state.currentuserinfo
      }
    },
    beforeDestroy() {

    },
    watch: {
        //监听状态决定是否获取初始
        customEventInfo(val) {
            var {customEvent,item,from} = val
            if(!customEvent) return
            if(JSON.stringify(val)=='{}') return
            this.$set(this,'currentCustomEventInfo',val);//当前事件相关信息
            console.log(
              `%c 客户化事件: ${customEvent} %c  from: ${from} %c`,
              'background:#35495e ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff',
              'background:#41b883 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
              'background:transparent'
            )
            this.$actions.setGlobalState({
                /**
                 * customEvent: 方法名
                 * item: 参数
                 * form: 从何处触发
                 */
                customEventInfo: {},
            });
            this.$nextTick((_)=>{
                this.$refs['customization'][customEvent](item)
            })

        }
    },
    methods:{

    },
    mounted() {

    }
}
</script>
<style lang="less" scoped>

</style>
