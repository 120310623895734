<template>
  <el-menu-item v-if="!item.children || item.children.length == 0" :index="String(item.id)" :key="item.id"
    @click="menuClickBtn(item)">
      <div :title="item.name" class="menu-item-content">
        <i class="lowcode" :class="item.css"></i>
        <span :title="item.name" slot="title">{{ item.name }}</span>
      </div>
  </el-menu-item>
  <el-submenu popper-class="abb-popper-menu" v-else :index="String(item.id)" :key="item.id">
    <template slot="title">
        <div :title="item.name" class="menu-item-content">
          <i class="lowcode" :class="item.css"></i>
          <span :title="item.name" slot="title">{{ item.name }}</span>
        </div>
    </template>

    <template v-for="child in item.children">
      <leftchildnav v-if="child.children && child.children.length > 0" :item="child" :key="child.id" />
      <el-menu-item v-else :index="String(child.id)" :key="child.id" @click="menuClickBtn(child)">
          <div :title="child.name" class="menu-item-content">
            <i class="lowcode" :class="child.css"></i>
            <span :title="child.name" slot="title">{{ child.name }}</span>
          </div>
      </el-menu-item>
    </template>
  </el-submenu>
</template>
<script>

export default {
  name: 'leftchildnav',
  props: ['item'],
  data() {
    return {}
  },
  methods: {
    menuClickBtn(item) {
      this.$emit('menuClickBtn',item)
    },
  },
}
</script>

<style>

</style>
