import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        //当前用户信息
        currentuserinfo: null,
        // 左侧菜单加载状态
        allMenuLoading: false,
        //菜单信息
        allMenuData: [],
        //是否请求驾驶舱信息
        isRequestPanelInfo: false,
        //当前widget信息
        allwidget: {},
        // 是否删除widget
        isDelwidget: false,
        //左侧机构信息
        allDeptData: [],
        //当前选中的组织信息
        currentSelectDeptInfo: {},
        //显示菜单信息
        viewMenuData: [],
        //左侧菜单搜索内容
        menuSearchValue:"",
        //左侧菜单默认选中的id
        defaultActiveIndex: "",

        menuAndDrawer: false, //左侧菜单以及应用详情抽屉显示状态
        //当前选中的菜单信息
        currentSelectMenuInfo:null,
        updateMainTable:false,
        otherToken:"",
        frontTimerConf:{},
        indexSignatureoptionData:'',
        useOtherToken:false,
        commonLanguageList:{},// 通用组件的国际化
        //--------------------转到功能用到的全局变量---------------------------
        shareDetailInfo: {},
        shareChildDetailInfo: {},


        // ----------------------------------------------------------------
        dashboardOptions: {},//dashboard用到的数据字典
        dbData:[],
        showimgconfig:false,

        bigdeptcode:"221027388",

        panellist:[],
        timer:"1",
        financeView:"221027388",
        // financeView:"221027388",
        overviewdept:"221027388",
        overviewdept2:"221027632",
        financetype:"按月",
        financevule:"1",
        jump:false,
    },
    mutations: {
        settimer(state,timer){
            state.timer = timer
        },
        setjump(state,jump){
            state.jump = jump
        },
        setpanellist(state,panellist){
            state.panellist = panellist
        },
        setfinancetype(state,financetype){
            state.financetype = financetype
        },
        setfinancevule(state,financevule){
            state.financevule = financevule
        },
        setoverviewdept(state,overviewdept){
            state.overviewdept = overviewdept
        },
        setfinanceView(state,financeView){
            state.financeView = financeView
        },
        setbigdeptcode(state,bigdeptcode){
            state.bigdeptcode = bigdeptcode
        },
        setshowimgconfig(state,showimgconfig){
            state.showimgconfig = showimgconfig
        },
        setUseOtherToken(state,useOtherToken){
            state.useOtherToken = useOtherToken
        },
        setIndexSignatureoptionData(state,indexSignatureoptionData){
            state.indexSignatureoptionData = indexSignatureoptionData
        },
        setFrontTimerConf(state,frontTimerConf){
            state.frontTimerConf = frontTimerConf
        },
        setOtherToken(state,otherToken){
            state.otherToken = otherToken
        },
        //获取当前用户信息
        setcurrentuserinfo(state,currentuserinfo){
            state.currentuserinfo = currentuserinfo
        },
        //左侧菜单加载状态
        setallMenuLoading(state, allMenuLoading) {
            state.allMenuLoading = allMenuLoading
        },

        //左侧菜单信息
        setallMenuData(state, allMenuData) {
            state.allMenuData = allMenuData
        },
        //左侧菜单信息
        setallwidget(state, allwidget) {
            state.allwidget = allwidget
        },


        //显示菜单信息
        setViewMenuData(state, viewMenuData) {
            state.viewMenuData = viewMenuData
        },
        //左侧菜单搜索内容
        setmenuSearchValue(state, menuSearchValue) {
            state.menuSearchValue = menuSearchValue
        },
        //左侧菜单默认选中的id
        setdefaultActiveIndex(state, defaultActiveIndex) {
            state.defaultActiveIndex = defaultActiveIndex
        },
        //左侧菜单以及应用详情抽屉显示状态
        setmenuAndDrawer(state, menuAndDrawer) {
            state.menuAndDrawer = menuAndDrawer
        },
        // 当前选中的菜单信息
        setCurrentSelectMenuInfo(state,currentSelectMenuInfo){
            state.currentSelectMenuInfo = currentSelectMenuInfo
        },
        //客户化方法信息
        setcustomEventInfo(state,customEventInfo){
            state.customEventInfo = customEventInfo
        },
        // dept树数据
        setallDeptData(state,allDeptData){
            state.allDeptData = allDeptData
        },
        // 当前选中dept信息
        setcurrentSelectDeptInfo(state,currentSelectDeptInfo){
            state.currentSelectDeptInfo = currentSelectDeptInfo
        },
        // 设置dashboard用到的数据字典
        setdashboardOptions(state,options) {
            state.dashboardOptions = options
        },
        setDbDataFn(state,options){
            state.dbData = options
        },
        //是否重新请求驾驶舱数据
        setisRequestPanelInfo(state,isRequestPanelInfo) {
            state.isRequestPanelInfo = isRequestPanelInfo
        },
        //是否重新请求驾驶舱数据
        setisDelwidget(state,isDelwidget) {
            state.isDelwidget = isDelwidget
        },
        // 通用组件的国际化
        setcommonLanguageList(state,commonLanguageList){
            state.commonLanguageList = commonLanguageList
        },
    },
    actions: {
    },
    modules: {
    }
})
