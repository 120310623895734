<template>
  <div class="abb_header">
    <!-- <div class="abb_header_top">
      <font>ABB 智慧能源平台</font>
      <img class="aside-logo" src="@/assets/imgs/logo-color.png" />
    </div> -->
    <div class="abb_header_content">
      <!-- <img
        class="aside-logo"
        src="@/assets/imgs/logo-color.png"
        style="width: 42px; position: relative; left: 20px; z-index: 999"
      /> -->
      <img
        class="aside-logo"
        src="@/assets/imgs/login/abb.svg"
        style="width: 42px; position: relative; left: 20px; z-index: 999"
      />
      <el-menu
        class="abb-menu-demo"
        mode="horizontal"
        :collapse-transition="false"
        background-color="#ffffff"
        text-color="#4e5969"
        :default-active="defaultActiveIndex"
        :collapse="isCollapse"
        :default-openeds="openeds"
        style="margin-left: 40px"
      >
        <template v-for="item in viewMenuData">
          <el-menu-item
            v-if="!item.children || item.children.length == 0"
            :index="String(item.id)"
            :key="item.id"
            @click="menuClickBtn(item)"
          >
            <div :title="item.name" class="menu-item-content" v-if="item.name !='widget管理' && item.name !='widget'">
              <img
                src="@/assets/imgs/echarts/zonglan.svg"
                style="width: 16px; height: 16px; margin-right: 8.5px"
                v-if="item.name === '总览'"
              />
              <img
                src="@/assets/imgs/echarts/nengyuan.svg"
                style="width: 18px; height: 18px; margin-right: 8.5px"
                v-else-if="item.name === '能源管理'"
              />
              <img
                src="@/assets/imgs/echarts/tongji.svg"
                style="width: 18px; height: 18px; margin-right: 8.5px"
                v-else-if="item.name === '统计报告'"
              />
              <img
                src="@/assets/imgs/big/CM.svg"
                style="width: 16px; height: 16px; margin-right: 8.5px"
                v-else-if="item.name === '碳管理'"
              />
              <img
                src="@/assets/imgs/echarts/abb_settings_32.svg"
                style="width: 16px; height: 16px; margin-right: 8.5px"
                v-else-if="item.name === '系统设置'"
              />
              <img
                src="@/assets/imgs/echarts/yunwei.svg"
                style="width: 18px; height: 18px; margin-right: 8.5px"
                v-else-if="item.name === '运维管理'"
              />
              <img
                src="@/assets/imgs/echarts/shebei.svg"
                style="width: 16px; height: 16px; margin-right: 8.5px"
                v-else-if="item.name === '设备管理'"
              />
              <i class="lowcode" :class="item.css" v-else></i>
              <span :title="item.name" slot="title">{{ item.name }}</span>
            </div>
          </el-menu-item>
          <el-submenu
            popper-class="abb-popper-menu"
            v-else
            :index="String(item.id)"
            :key="item.id"
          >
            <template slot="title">
              <div :title="item.name" class="menu-item-content">
                <img
                  src="@/assets/imgs/echarts/shebei.svg"
                  style="width: 18px; height: 18px; margin-right: 8.5px"
                  v-if="item.name === '设备管理'"
                />
                <img
                  src="@/assets/imgs/echarts/yunwei.svg"
                  style="width: 18px; height: 18px; margin-right: 8.5px"
                  v-else-if="item.name === '运维管理'"
                />
                <img
                  src="@/assets/imgs/echarts/zonglan.svg"
                  style="width: 18px; height: 18px; margin-right: 8.5px"
                  v-else-if="item.name === '总览'"
                />
                <img
                  src="@/assets/imgs/echarts/abb_settings_32.svg"
                  style="width: 16px; height: 16px; margin-right: 8.5px"
                  v-else-if="item.name === '系统设置'"
                />
                <i class="lowcode" :class="item.css" v-else></i>
                <span :title="item.name" slot="title">{{ item.name }}</span>
              </div>
            </template>
            <template v-for="child in item.children">
              <leftchildnav
                v-if="child.children && child.children.length > 0"
                :item="child"
                :key="child.id"
                @menuClickBtn="menuClickBtn"
              />
              <el-menu-item
                v-else
                :index="String(child.id)"
                :key="child.id"
                @click="menuClickBtn(child)"
              >
                <div :title="child.name" class="menu-item-content1">
                  <i class="lowcode" :class="child.css"></i>
                  <span :title="child.name" slot="title">{{ child.name }}</span>
                </div>
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
      </el-menu>
      <div
        class="abb_header_content_treat"
        @click="onClick"
        style="top: -4px; left: -15px"
      >
        <!-- <i
          class="el-icon-bell"
          style="font-size: 23px; line-height: 40px; font-weight: 600"
        ></i>
        <span>{{ this.remainingTaskCounts }}</span> -->
        <img
          style="width: 25px; height: 25px; margin-top: 10px"
          src="@/assets/imgs/echarts/Picture2.svg"
          alt=""
        />
        <span>{{
          this.remainingTaskCounts > 99 ? "99⁺" : this.remainingTaskCounts
        }}</span>
      </div>
      <div class="abb_header_content_aside">
        <span class="time_box">
          <!-- <i class="lowcode icon-a-shijianxuanzeqitimeselect"></i> -->
          <img
            src="@/assets/imgs/echarts/abb_time_32.svg"
            @click="clicktime"
            style="width: 16px; height: 16px"
            alt=""
          />
          <font>{{LangHandler('custheadupdate','最新更新')}}：{{ $moment().format("HH:mm:ss") }}</font>
        </span>
        <a-dropdown :trigger="['click']">
          <span
            class="huan"
          >
            <span class="info_box">
              <img
                :src="currentuserinfo.HEADIMGURL"
                v-if="currentuserinfo.HEADIMGURL"
              />
              <font v-else>{{ USERNAME }}</font>
            </span>
          </span>
          <a-menu slot="overlay" @click="handleMenuClick">
            <a-menu-item key="personSet">
              <!-- <i class="el-icon-user-solid"></i> -->
              <!-- style="width: 14px; height: 14px;padding-top: 2px;" -->
              <img
                src="@/assets/imgs/echarts/abb_user_32.svg"
                alt=""
                class="AddInformation_img"
              />
               <span class="AddInformation">{{LangHandler('custheadinfo','个人信息')}}</span>
            </a-menu-item>
            <!-- <a-menu-item key="tankuang">
              <img
                src="@/assets/imgs/echarts/abb_user_32.svg"
                alt=""
                class="AddInformation_img"
              />
              <span class="AddInformation">弹框</span>
            </a-menu-item> -->
            <!-- <a-menu-item key="systemSet">
                  <i class="lowcode icon-a-5shezhi"></i>
                    系统设置</a-menu-item> -->
                
            <a-menu-item key="signOutBtn">
              <!-- <i class="lowcode icon-zhinanzhen"></i> -->
              <!-- style="width: 16px; height: 16px;padding-top: 4px; padding-right:2px;" -->
              <img
                src="@/assets/imgs/echarts/abb_log-out_32.svg"
                alt=""
                class="AddRetreat_img"
              />
              <span class="AddRetreat">{{LangHandler('custheadlogout','退出登录')}}</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <person-modal ref="personModal" />
    <div>
      <el-dialog :title="LangHandler('custheadmsg','消息中心')" :visible.sync="dialogVisible" width="80%">
        <treat></treat>
        <span slot="footer" class="dialog-footer">
          <div
            style="background: white !important; color: black; color: black"
            class="lxhbtn"
            @click="dialogVisible = false"
          >
            {{LangHandler('personmodalcancle','取消')}}
          </div>
                   
          <div
            style="background: #3366ff !important"
            class="lxhbtn"
            @click="dialogVisible = false"
          >
            {{LangHandler('custheaddetermine','确定')}}
          </div>
        </span>
      </el-dialog>
    </div>
    <div>
      <el-dialog title="生成报告" :visible.sync="dialogVisible1" width="30%">
        <OperationCom7></OperationCom7>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import OperationCom7 from "@/component/OperationCom/OperationCom7";
import leftchildnav from "@/views/navindex/leftchildnav";
import personModal from "@/component/custHead/personModal.vue";
import treat from "./treat.vue";
export default {
  name: "custHead",
  components: { leftchildnav, personModal, treat, OperationCom7 },
  computed: {
    // 当前用户信息
    currentuserinfo() {
      return this.$store.state.currentuserinfo;
    },
    USERNAME() {
      var name = this.currentuserinfo.USERNAME;
      return name.substring(0, 2);
    },
    // 菜单列表数据
    viewMenuData() {
      return this.$store.state.viewMenuData;
    },
    // 当前选中的菜单下标
    defaultActiveIndex() {
      return this.$store.state.defaultActiveIndex;
    },
    setTreat() {
      return this.$store.state.setTreat;
    },
  },
  data() {
    return {
      openeds: [],
      isCollapse: false,
      dialogVisible: false,
      remainingTaskCounts: null,
      remainingTaskCounts2:null,
      dialogVisible1: false,
    };
  },
  mounted() {
    console.log(this.LangHandler('annexname','名称'))
    this.getremainingTaskCounts();
  },
  methods: {
    clicktime() {
      // let routeUrl = this.$router.resolve({
      //   path: "/bigscreen1",
      // });
      // window.open(routeUrl.href, '_blank');
    },
    onClick() {
      this.dialogVisible = true;
    },
    getremainingTaskCounts() {
      var url1 = Config.dev_url + "/api-apps/workflow/todoAndDoneTask/remainingTaskCounts?user=" + this.currentuserinfo.USERNAME;
      var url =
        Config.dev_url +
        "/api-apps/app/REMINDER/list"
        let data = {
          page:"1",
          limit:"10",
          p_query: `{\"links\":[{\"key\":\"REMINDERUSER\",\"links\":[],\"type\":\"Like\",\"value\":\"${this.currentuserinfo.USERNAME}\"}],\"type\":\"LinkAnd\"}`
        }
      this.$axios.get(url1).then((res) => {
        this.remainingTaskCounts2 = Number(res.data.data);
      });
      this.$axios.post(url,data).then((res) => {
        this.remainingTaskCounts = Number(res.data.count) + this.remainingTaskCounts2;
      });
    },
    menuClickBtn(item) {
      console.log(item); // https://electrification.iot.ability.abb.com.cn // https://iot-maks.lshenergy.com
      // if (item.name == "大屏") {
      //   let routeUrl = this.$router.resolve({
      //     path:
      //       "https://electrification.iot.ability.abb.com.cn/el3d/?token" +
      //       sessionStorage.getItem("application_access_token"), // 这里的路径就可以正常的写，不需要添加_blank: true
      //   });
      //   window.open(
      //     "https://electrification.iot.ability.abb.com.cn/el3d/?token=" +
      //       sessionStorage.getItem("application_access_token"),
      //     "_blank"
      //   );
      //   return;
      // }
      if (item.name == "园区驾驶舱") {
        let routeUrl = this.$router.resolve({
          path:
            "https://iot-maks.lshenergy.com/parkscreen"
        });
        window.open(
          "https://iot-maks.lshenergy.com/parkscreen",
          "_blank"
        );
        return;
      }
      if (item.name == "园区大屏") {
              let routeUrl = this.$router.resolve({
                path: "/bigscreen3",
              });
        window.open(routeUrl.href, "_blank");
        return
      }
      if (item.name == "驾驶舱") {
        let routeUrl = this.$router.resolve({
          path: "/bigscreen3",
        });
        window.open(routeUrl.href, "_blank");
        return
      }
      if (item.name == "财务大屏") {
        let routeUrl = this.$router.resolve({
          path: "/bigscreen2",
          query:{
              type: 2,
          }
        });
        window.open(routeUrl.href, "_blank");
        return
      }
      if (item.name == "运维大屏") {
        let routeUrl = this.$router.resolve({
          path: "/bigscreen2",
          query:{
              type: 3,
          }
        });
        window.open(routeUrl.href, "_blank");
        return
      }
      console.log("当前点击的菜单", { ...item });
      this.$store.commit("setdefaultActiveIndex", String(item.id));
      this.$store.commit("setCurrentSelectMenuInfo", item);
      //向子应用传递数据
      this.$actions.setGlobalState({
        currentuserinfo: this.$store.state.currentuserinfo,
        currentSelectMenuInfo: item,
        commonLanguageList:this.$store.state.commonLanguageList,
      });
      //跳转到通用解析页面
      if (item.menutype == "0" && !!item.associationkey) {
        this.$router.push({
          path: "/productGeneral/generalApp/" + item.associationkey,
          query: {
            now: new Date().getTime(),
            title: item.name,
          },
        });
      } else if (item.menutype == "1") {
        this.$router.push({
          path: "/productGeneral/GeneralReport/" + item.associationkey,
          query: {
            now: new Date().getTime(),
            title: item.name,
          },
        });
      } else {
        if (item.url.indexOf("getewayGeneral") != -1) {
          this.$nextTick(() => {
            let mainW = document.getElementById("vmMain").clientWidth;
            console.log(mainW);
            this.$actions.setGlobalState({ mainW: mainW }); //通过setGlobalState改变全
          });
        }
        this.$router.push({
          path: item.url,
          query: {
            now: new Date().getTime(),
            title: item.name,
          },
        });
      }
    },

    handleMenuClick(e) {
      if (e.key == "tankuang") {
        this.dialogVisible1 = true;
      } else {
        var event = e.key;
        console.log("click", e.key);
        this[event]();
      }
    },
    // 个人信息
    personSet() {
      console.log("打开个人信息");
      // 无
      this.$refs.personModal.show();
    },
    // 系统设置
    systemSet() {
      // 无
    },
    chengeskin(){
      // document.getElementById('skin').href ='/yellow.css'
    },
    //退出登录
    signOutBtn() {
      //清除本地缓存
      sessionStorage.clear();
      this.$store.commit("setcurrentuserinfo", {});
      this.$router.push("/");
    },
  },
};
</script>

<style lang="less" scoped>
.abb_header {
  height: 50px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  .abb_header_top {
    height: 32px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #1f1f1f;
    line-height: 32px;
    position: relative;
    box-shadow: 0px -1px 0px 0px rgba(227, 230, 234, 0.5) inset;
    .aside-logo {
      position: absolute;
      left: 10px;
      top: 9px;
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      height: 15px;
      width: auto;
    }
  }
  .abb_header_content {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .abb-menu-demo {
      flex: 1;
    }
    .abb_header_content_aside {
      display: flex;
      align-items: center;
      .time_box {
        padding-right: 12px;
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        color: black;
        line-height: 22px;
        box-shadow: 1px 0px 1px 0px rgba(0, 0, 0, 0.05);
        i {
          margin-right: 6px;
        }
      }
      .info_box {
        // margin-left: 24px;
        // margin-right: 19px;
        margin: 1px auto;
        width: 28px;
        height: 28px;
        box-sizing: border-box;
        display: block;
        background: #99ccff;
        border: 1.6px solid #f9f9f9;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        overflow: hidden;
        font {
          font-size: 12px;
          font-weight: 600;
          color: #ffffff;
        }
        img {
          width: inherit;
          height: inherit;
        }
      }
    }
    .abb_header_content_treat {
      font-size: 12px;
      margin-right: 1%;
      cursor: pointer;
      height: 32px;
      line-height: 32px;
      position: relative;
      span {
        position: absolute;
        width: 18px;
        height: 18px;
        background-color: #e9611f;
        border-radius: 50%;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
        font-size: 12px;
        top: 4px;
        left: 15px;
      }
    }
  }
  .el-dialog {
    height: calc(100vh) !important;
  }
}
.menu-item-content1 {
  width: 100%;
  text-align: center;
  height: 32px;
  line-height: 32px;
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 400;
  margin-left: 4px;
}
.menu-item-content1:hover {
  background: rgba(208, 222, 255, 0.6);
  color: #205de8;
  // text-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05);
}
.AddInformation_img,
.AddRetreat_img {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 11px;
}
.AddInformation,
.AddRetreat {
  margin-left: 26px;
  color: black;
}
.ant-dropdown-menu-item {
  position: relative;
}
.huan{
  height: 36px;
  width: 36px;
  margin-right: 18px;
  margin-left: 24px;
  border-radius: 50%;
  border: 3px solid #3e79fc;
}
</style>
