<template>
<a-drawer :title="LangHandler('custheadinfo','个人信息')" :width="420" :visible="visible" :body-style="{ paddingBottom: '80px' }" @close="modalClosed">
    <div class="person-content">
        <div class="person-tabName">
            <span></span>
            <font>{{LangHandler('personmodalinfo','基本信息')}}</font>
        </div>
        <div class="person-tabWrap">
            <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }">
                <!-- <a-form-item label="头像">
                        <a-upload
                            name="avatar"
                            list-type="picture-card"
                            class="avatar-uploader"
                            :show-upload-list="false"
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            :before-upload="beforeUpload"
                            @change="handleChange"
                        >

                            <div v-if="imageUrl" class="person-portrait">
                                <img  :src="imageUrl" alt="avatar" />
                                <span>修改</span>
                            </div>
                            <div v-else class="emyty-portrait">
                                <font>{{ prefix_NICKNAME }}</font>
                                <span>修改</span>
                            </div>
                        </a-upload>

                        <div class="userIcon" @click="uploadHeadimg">
                            <div v-if="imageUrl" class="person-portrait">
                                <img  :src="imageUrl" alt="avatar" />
                                <span>修改</span>
                            </div>
                            <div v-else class="emyty-portrait">
                                <font>{{ prefix_NICKNAME }}</font>
                                <span>修改</span>
                            </div>
                        </div>

                    </a-form-item> -->
                <a-form-item :label="LangHandler('personcompany','公司')">
                    <a-input :disabled="true" v-decorator="['ORGCODE', { rules: [{ required: false, message: '请填写公司!' }] }]" />
                </a-form-item>
                <a-form-item :label="LangHandler('personname','姓名')">
                    <a-input :disabled="true" v-decorator="['NICKNAME', { rules: [{ required: false, message: '请填写姓名!' }] }]" />
                </a-form-item>
                <a-form-item :label="LangHandler('personmail','邮箱')">
                    <a-input :disabled="true" v-decorator="['EMAIL', { rules: [{ required: true, message: '请填写邮箱!' }] }]" />
                </a-form-item>
                <!-- <a-form-item label="电话">
                        <a-inpu :disabled="true" v-decorator="['PHONE', { rules: [{ required: true, message: '请填写电话!' }] }]"/>
                    </a-form-item> -->
                <!-- <a-form-item :label="LangHandler('personcolor','主题色')">
                    <div class="themeColor">
                        <div class="themeColor-item" v-for="(item,index) in themeColor" :key="index" @click="switchthemeColor(item)">
                          
                            <div class="themeColor-item-bottom">
                                <el-checkbox :true-label="item.color" :false-label="item.color" v-model="currentuserinfo.THEMECOLOR">{{item.name}}</el-checkbox>
                            </div>
                        </div>
                    </div>
                </a-form-item> -->
                <a-form-item :label="LangHandler('personlang','语言')">
                    <a-select v-model="currentuserinfo.LANG" >
                        <a-select-option v-for="(item,index) in langType" :key="index" :value="item.code">{{item.name}}</a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>

        </div>
    </div>

    <div :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }">
        <a-button :style="{ marginRight: '8px' }" @click="modalClosed">
            {{LangHandler('personmodalcancle','取消')}}
        </a-button>
        <a-button type="primary" @click="saveBtn2">
            {{LangHandler('personmodalsave','保存')}}
        </a-button>
    </div>
</a-drawer>
</template>

  
<script>
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
export default {
    name: 'personModal',
    components: {},
    props: {

    },
    watch: {

    },
    data() {
        return {
            visible: false,
            form: this.$form.createForm(this, {
                name: 'personForm'
            }),
            detailInfo: {},
            loading: false,
            imageUrl: '',
            themeColor: [{
                    id: 1,
                    name: "默认",
                    color: "#1C74F1",
                },
                {
                    id: 5,
                    name: "明黄",
                    color: "#F9BA08",
                },
            ],
            langType: [],
        }
    },
    computed: {
        // 当前登录用户信息
        currentuserinfo() {
            return this.$store.state.currentuserinfo
        },
        prefix_NICKNAME() {
            var name = this.currentuserinfo.NICKNAME
            return name.substring(0, 2)
        },
    },
    methods: {
        //切换主题色
        switchthemeColor(item) {

            this.currentuserinfo.THEMECOLOR = item.color
            document.documentElement.setAttribute("theme-mode", this.currentuserinfo.NAVSTYLE);
            document.documentElement.setAttribute("theme-color", this.currentuserinfo.THEMECOLOR);
        },
        handleChange(info) {
            getBase64(info.file.originFileObj, imageUrl => {
                this.imageUrl = imageUrl;
                this.loading = false;
            });
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                this.$message.error('You can only upload JPG file!');
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('Image must smaller than 2MB!');
            }
            return isJpgOrPng && isLt2M;
        },
        saveBtn2() {
            let data = {
                themecolor: this.currentuserinfo.THEMECOLOR,
                ID: this.detailInfo.ID,
                LANG:this.currentuserinfo.LANG
            }
            console.log(data)

            let url = Config.dev_url + "/api-apps/mobile/users/me";
            this.$axios.put(url, data).then((res) => {
                if (this.currentuserinfo.THEMECOLOR == '#1C74F1') {
                    document.getElementById('skin').href = '/blue.css'
                } else {
                    document.getElementById('skin').href = '/yellow.css'
                }
                let val = this.currentuserinfo.LANG || 'CH'
                sessionStorage.setItem('locale',val)
                this.$i18n.locale = val;
                //向子应用传递数据
                this.$actions.setGlobalState({
                    currentLocale : val,
                });
                this.getMenuList()
                this.$message.success(res.data.msg)
                location.reload();
            })
        },
        // 设置语言后重新请求菜单
        updateMenu(){

        },
        saveBtn() {
            this.form.validateFields((err, values) => {
                if (!err) {
                    var detail = {}
                    Object.assign(detail, this.detailInfo, values, {
                        HEADIMGURL: this.imageUrl
                    })
                    console.log(detail, "detail")
                    let url = Config.dev_url + "/api-apps/users/me";
                    this.$axios.put(url, detail).then((res) => {
                        if (res.data.code == 0) {
                            this.$message.success(res.data.msg)
                            this.setCurrentUser()
                            this.visible = false;
                        } else {
                            this.$message.warning(res.data.msg)
                        }
                    }).catch((err) => {
                        console.log(err)
                    })
                }
            });
        },
        setCurrentUser() {
            const url = Config.dev_url + "/api-user/users/currentMap";
            this.$axios.get(url, {}).then((res) => {
                this.$store.commit("setcurrentuserinfo", res.data);
            })
        },
        //获取用户信息
        getUserInfo() {
            return new Promise((resolve) => {
                var url = Config.dev_url + '/api-apps/users/me';
                this.$axios.get(url, {}).then((res) => { //获取当前用户信息
                    this.detailInfo = res.data
                    resolve(res.data)
                }).catch((err) => {});
            }).catch((err) => {})
        },
        show() {
            // this.getUserInfo().then((currentuserinfo) => {
            this.detailInfo = JSON.parse(JSON.stringify(this.currentuserinfo))
            this.getLangType()
            this.visible = true
            this.$nextTick(() => {
                // var detail = {}
                // detail.HEADIMGURL = currentuserinfo.HEADIMGURL;//头像
                // detail.ORGCODE = currentuserinfo.ORGCODE;//公司
                // detail.ORGNAME = currentuserinfo.ORGNAME;//公司
                // detail.NICKNAME = currentuserinfo.NICKNAME;//姓名
                // detail.EMAIL = currentuserinfo.EMAIL;//邮箱
                // detail.PHONE = currentuserinfo.PHONE;//电话
                this.form.setFieldsValue(this.detailInfo);
                this.imageUrl = this.detailInfo.HEADIMGURL
                console.log({
                    ...this.detailInfo
                }, "imageUrl")
            })
            // })
        },
        modalClosed() {
            this.visible = false
        },

        // 上传
        fileUpload(callBack) {
            var iElement = document.createElement('input');
            iElement.type = 'file';
            iElement.name = 'file';
            iElement.value = '';
            iElement.onchange = (e => {
                const flies = iElement.files;
                const value = iElement.value;
                if (this.isUpload(value)) {
                    return callBack(flies)
                }
            })
            iElement.click()
        },

        isUpload: function (imgPath) {
            if (this.isImg(this.getUploadUrlSuffix(imgPath))) {
                return true;
            } else {
                this.$message.warning("只能上传图片格式的(jpg|jpeg|png)");
                return false;
            }
        },
        /**
         * @description 是否为图片 目前仅支持 jpg jpeg png
         * @param {*} str 文件类型
         */
        isImg: function (str) {
            if (str.search("[.]+(jpg|jpeg|png|JPG|JPEG|PNG)$")) {
                return false;
            }
            return true;
        },
        /**
         * @description 截取url后面的文件类型
         * @param {*} urlStr 文件名
         */
        getUploadUrlSuffix: function (urlStr) {
            var url = urlStr.substring(urlStr.lastIndexOf("\."), urlStr.length);
            return url;
        },

        // 上传头像
        uploadHeadimg(files) {
            console.log("点击了", Object.prototype.toString.call(files))
            if (files == '[object PointerEvent]' || files == '[object MouseEvent]') { //点击事件时执行
                this.fileUpload(this.uploadHeadimg)
                return
            }
            if (files == '[object FileList]') { //选择文件后返回
                var formData = new FormData();
                formData.append("fileList", files[0]);
                formData.append('type', 1)
                var config = {
                    //添加请求头
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                }
                const url = Config.dev_url + "/api-file/file/uploads";
                this.$axios.post(url, formData, config).then((res) => {
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg)
                        this.imageUrl = res.data.data[0].webUrl
                    } else {
                        // this.$message.warning(res.data.msg)
                    }
                })
            }
        },

        getLangType() {
            let url = Config.dev_url + '/api-user/dicts/findByCode?code=LANGUAGE';
            this.$axios.get(url).then((res) => {
                this.langType = res.data.subDicts
                if (!this.currentuserinfo.LANG) {
                    this.$set(this.currentuserinfo, 'LANG', 'CH')
                }
            }).catch((err) => {});
        },

        getMenuList() {
            this.$store.commit("setallMenuLoading", true)
            if (!!this.currentuserinfo.rolecode) {
                var url = Config.dev_url + '/api-user/menus/findMenuByRoleCode/' + this.currentuserinfo.rolecode + "?hidden=0";
                this.$axios.get(url).then((res) => {
                    sessionStorage.setItem("allMenuData", JSON.stringify(res.data));
                    this.$store.commit("setallMenuData", [])
                    this.$store.commit("setViewMenuData", [])
                    this.$store.commit("setallMenuData", res.data)
                    this.$store.commit("setViewMenuData", res.data)
                }).catch((err) => {
                    this.$store.commit("setallMenuData", [])
                    this.$store.commit("setViewMenuData", [])
                }).finally(() => {
                    this.$store.commit("setallMenuLoading", false)
                })
            }
        }
    },

    mounted() {

    }
}
</script>

<style lang="less">
.ant-drawer-wrapper-body {
    margin: 0;
    padding: 0;
    height: 800px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .ant-drawer-body {
        padding: 0 !important;
    }

    .person-header {
        height: 67px;
        position: relative;

        .psrson-modal-title {
            font-size: 22px;
            font-weight: 500;
            color: #1b1e26;
            line-height: 28px;
            display: inline-block;
            margin-top: 20px;
            margin-left: 32px;
        }

        .icon-close {
            position: absolute;
            top: 2px;
            right: 14px;
            color: #bac1c9;
            font-weight: 100;
            font-size: 40px;
            cursor: pointer;
        }
    }

    .person-content {
        flex: 1;
        padding-top: 24px;
        overflow: auto;
        
        .person-tabName {
            margin-left: 32px;
            margin-bottom: 16px;

            span {
                transform: translateY(2px);
                width: 4px;
                height: 17px;
                display: inline-block;
                background: #1d74f1;
                margin-right: 10px;
                border-radius: 1px;
            }

            font-size: 18px;
            font-weight: 500;
            color: #1d2129;
            line-height: 26px;
        }

        .person-tabWrap {
            width: 100%;
            padding-left: 20px;
            .ant-form-item {
                margin-bottom: 0;
            }

            .userIcon {
                padding: 0;

                width: 140px;
                height: 140px;
                box-sizing: border-box;
                // background: #7aa3ff;
                // border-radius: 3px;

            }

            .emyty-portrait {
                height: inherit;
                background: #7aa3ff;
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                font {
                    font-size: 64px;
                    font-weight: 500;
                    color: #ffffff;
                }

                span {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 140px;
                    height: 28px;
                    background: rgba(0, 0, 0, 0.40);
                    border-radius: 0px 0px 4px 4px;
                    line-height: 28px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                }
            }

            .person-portrait {
                position: relative;

                img {
                    width: 140px;
                    height: 140px;
                }

                span {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 140px;
                    height: 28px;
                    background: rgba(0, 0, 0, 0.40);
                    border-radius: 0px 0px 4px 4px;
                    line-height: 28px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #ffffff;
                }
            }
        }
    }

    .person-footer {
        height: 60px;
        box-shadow: 0px -4px 10px 0px rgba(78, 89, 105, 0.06);
    }

}
</style>
