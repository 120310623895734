<template>
  <div style="   padding: 15px;background: white;padding-top: 0px;height: 250px;">
    <div style="display:flex;align-items: center;">
      <div style="width: 3px;height: 12px;background: #F9BA08;margin-right: 9px;"></div>
      <div>设备</div>
    </div>
    <div style="width:100%;justify-content: center;display: flex;">
      <Panel1></Panel1>
      
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      
    }
  },
  components: {
    Panel1: () => import("@/component/custom/Panel1.vue"),
    Panel2: () => import("@/component/custom/Panel2.vue"),
  },
  computed:{
   
  },
  watch: {

  },
  methods: {
    
  },
  mounted(){
    
  }
}
</script>

<style scoped lang='less'>

</style>
