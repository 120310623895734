
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Element from 'element-ui'
import enLocale from 'element-ui/lib/locale/lang/en' // 饿了么的英文包
import zhLocale from 'element-ui/lib/locale/lang/zh-CN' // 饿了么的中文包
Vue.use(VueI18n)

const messages = {
    EN: {
        ...enLocale
    },
    CH: {
        ...zhLocale
    }
}
const i18n = new VueI18n({
    locale: sessionStorage.getItem('locale') || 'CH', // 设置地区
    messages // 设置地区信息
})

Vue.use(Element, {
    i18n: (key, value) => i18n.t(key, value)
})

export default i18n