<template>
  <div id="wrap">
    <el-row class="row_wrap" :gutter="16">
      <el-col :span="24">
        <div class="left">
          <div class="task_wrap">
            <div class="task_box">
              <div class="img_wrap">
                <img src="@/assets/imgs/echarts/Picture5.svg" alt="" />
              </div>
              <div class="task_content">
                <div class="title">{{LangHandler('treateventtotal','事件总数')}}</div>
                <div class="num_wrap">
                  <div class="num">{{ shijiantotal }}</div>
                  <div class="Symbol">{{LangHandler('treatitem','项')}}</div>
                </div>
              </div>
            </div>
            <div class="task_box">
              <div class="img_wrap">
                <img src="@/assets/imgs/echarts/Picture4.svg" alt="" />
              </div>
              <div class="task_content">
                <div class="title">{{LangHandler('treatwaittotal','待办总数')}}</div>
                <div class="num_wrap">
                  <div class="num">{{ remainingTaskCounts }}</div>
                  <div class="Symbol">{{LangHandler('treatitem','项')}}</div>
                </div>
              </div>
            </div>
            <div class="task_box">
              <div class="img_wrap">
                <img src="@/assets/imgs/echarts/Picture3.svg" alt="" />
              </div>
              <div class="task_content">
                <div class="title">{{LangHandler('treatfinish','任务完成率')}}</div>
                <div class="num_wrap">
                  <div class="num">{{ taskCompletionRate }}</div>
                  <div class="Symbol">%</div>
                </div>
              </div>
            </div>
          </div>
          <div class="Todo_wrap">
            <div class="Todo_header">
              <div class="Todo_header_Tab">
                <div
                  class="TodoTab"
                  :class="{ active: TodoTab == 3 }"
                  @click="handlerTab('shijian', 'change')"
                >
                  {{LangHandler('treatlatest','最新事件')}}({{ shijiantotal }})
                </div>
                <div
                  class="TodoTab"
                  :class="{ active: TodoTab == 1 }"
                  @click="handlerTab('needdo', 'change')"
                >
                  {{LangHandler('treatwaittask','任务待办')}}({{ todototal }})
                </div>
                <div
                  class="TodoTab"
                  :class="{ active: TodoTab == 2 }"
                  @click="handlerTab('alreadydo', 'change')"
                >
                  {{LangHandler('treatfinshtask','任务已办')}}({{ donetotal }})
                </div>
              </div>
            </div>
            <div class="TodoList">
              <div
                class="Todo_item"
                v-for="(item, index) in TodoData"
                :key="index"
                v-if="TodoTab == 1"
                @click="viewTask(item)"
              >
                <div class="Todo_item_title">
                  {{ !!item.tododesc ? item.tododesc : item.nodename }}
                </div>
                <div class="Todo_content">
                  <span class="Todo_content_app">
                    <i class="lowcode icon-apps1"></i>
                    {{LangHandler('treatownapp','所属应用')}}：{{ item.bizappdesc }}
                  </span>
                  <span class="Todo_content_app" style="width: 250px;">
                    <i class="lowcode icon-huo2"></i>
                   {{LangHandler('treatcurnode','当前节点')}}：{{ item.nodename }}
                  </span>
                  <span class="Todo_content_app">
                    <i class="lowcode icon-yonghu3"></i>
                    {{LangHandler('treatputperson','提报人')}}：{{ item.starter }}
                  </span>
                  <span class="Todo_content_app" style="width: 210px;">
                    <i class="lowcode icon-riqilianxuan"></i>
                    {{LangHandler('treataccepttime','接收时间')}}：{{ item.tasktime }}
                  </span>
                </div>
              </div>

              <div
                class="Todo_item"
                v-for="(item, index) in DoneData"
                :key="index"
                v-if="TodoTab == 2"
                @click="viewTask(item)"
              >
                <div class="Todo_item_title">
                  {{ !!item.tododesc ? item.tododesc : item.nodename }}
                </div>
                <div class="Todo_content">
                  <span class="Todo_content_app">
                    <i class="lowcode icon-apps1"></i>
                    {{LangHandler('treatownapp','所属应用')}}：{{ item.bizappdesc }}
                  </span>
                  <span class="Todo_content_app" style="width: 250px;">
                    <i class="lowcode icon-huo2"></i>
                    {{LangHandler('treatcurnode','当前节点')}}：{{ item.nodename }}
                  </span>
                  <span class="Todo_content_app">
                    <i class="lowcode icon-yonghu3"></i>
                    {{LangHandler('treatputperson','提报人')}}：{{ item.starter }}
                  </span>
                  <span class="Todo_content_app">
                    <i class="lowcode icon-yijiejuede"></i>
                    {{LangHandler('treatapprovalidea','审批意见')}}：{{ !!item.opinion ? item.opinion : "无" }}
                  </span>
                  <span class="Todo_content_app" style="width: 210px;">
                    <i class="lowcode icon-riqilianxuan"></i>
                    {{LangHandler('treatapprovaltime','审批时间')}}：{{ item.opiniontime }}
                  </span>
                </div>
              </div>
              <div
                class="Todo_item"
                v-for="(item, index) in shijianData"
                :key="index"
                v-if="TodoTab == 3"
                @click="viewTask(item)"
              >
                <div class="Todo_content">
                  <span class="Todo_content_app" style="width: 230px;margin-right: 50px;">
                    <i class="lowcode icon-apps1"></i>
                    {{LangHandler('treattrigtime','事件触发时间')}}：{{ item.EVENTCREATETIME }}
                  </span>
                  <span class="Todo_content_app" style="width: 140px;margin-right: 50px;">
                    <!-- <i class="lowcode icon-huo2"></i> -->
                    {{LangHandler('treatsite','站点')}}：{{ item.STATIONNAME }}
                  </span>
                  <span class="Todo_content_app" style="width: 140px;margin-right: 50px;">
                    <!-- <i class="lowcode icon-yonghu3"></i> -->
                    {{LangHandler('treatequipment','设备')}}：{{ item.ASSETNAME }}
                  </span>
                  <span class="Todo_content_app" style="width: 140px;margin-right: 50px;">
                    <!-- <i class="lowcode icon-yonghu3"></i> -->
                    {{LangHandler('treatpoint','测点')}}：{{ item.VARIABLEREALNAME }}
                  </span>
                  <span class="Todo_content_app" style="width: 140px;margin-right: 50px;">
                    <!-- <i class="lowcode icon-yonghu3"></i> -->
                    {{LangHandler('treatevnettype','事件类型')}}：{{ item.EVENTTYPE }}
                  </span>
                  <span class="Todo_content_app" style="width: 140px;margin-right: 50px;">
                    <!-- <i class="lowcode icon-yonghu3"></i> -->
                    {{LangHandler('treatstate','状态')}}：{{ item.ALERTSTATUS }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="readmore"
              @click="LoadMore"
              v-if="TodoTab == 1 && remainderTodoLength > 0"
            >
              {{LangHandler('treatsurplus','剩余')}} {{ remainderTodoLength }} {{LangHandler('treatitems','条')}}
            </div>
            <div
              class="readmore"
              @click="LoadMore"
              v-else-if="TodoTab == 2 && remainderDoneLength > 0"
            >
              {{LangHandler('treatsurplus','剩余')}} {{ remainderDoneLength }} {{LangHandler('treatitems','条')}}
            </div>
            <div
              class="readmore"
              v-else
              style="color: #4e5969; cursor: default"
            >
              {{LangHandler('treattotaldata','已加载全部数据')}}
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <quickmenu
      ref="quickmenu"
      @sendquickmenuData="sendquickmenuData"
    ></quickmenu>
  </div>
</template>

<script>
import { mapState } from "vuex";
import quickmenu from "../../views/quickmenu.vue";
export default {
  name: "defaultIndex",
  components: { quickmenu },
  data() {
    return {
      TodoTab: 1,
      page: {
        currentPage: 1,
        pageSize: 10,
        totaltodoCount: 0, // 总待办数量
        totadoneCount: 0, // 总已办数量
        totashijianCount:0,
      },
      TodoData: [],
      DoneData: [],
      shijianData: [],
      todototal: null,
      donetotal: null,
      shijiantotal: null,
      searchList: {
        tododesc: "",
        nodename: "",
        opinion: "",
      },
      taskCompletionRate: null,
      todaysTaskCounts: null,
      remainingTaskCounts: null,
      allTaskCounts: null,
      quickmenuList: [],
      Manage: false,
      colorList: ["blue", "blue2", "orange", "green", "purple"],
    };
  },
  computed: {
    ...mapState(["currentuserinfo"]),
    //数据剩余未显示条数
    remainderTodoLength() {
      var num = this.page.totaltodoCount - this.TodoData.length;
      return num;
    },
    remainderDoneLength() {
      var num = this.page.totadoneCount - this.DoneData.length;
      return num;
    },
    remaindershijianLength() {
      var num = this.page.totadoneCount - this.shijianData.length;
      return num;
    },
    // currentuserinfo() {
    //   return this.$store.state.currentuserinfo;
    // },
    // USERNAME() {
    //   var name = this.currentuserinfo.USERNAME;
    //   return name;
    // },
  },
  methods: {
    //查看待办/已办任务
    viewTask(item) {
      console.log(item);
      let that = this;
      if (!!item.bizapp && !!item.bizid) {
        console.log(encodeURI(item.bizapp));
        let routeData = this.$router.resolve({
          path: "/productGeneral/generalApp/" + item.bizapp,
          query: {
            gotodetailid: item.bizid,
            type: "todo",
          },
        });
        console.log(routeData.href);
        layer.open({
          type: 2,
          area: ["90%", "90%"],
          title: item.bizappdesc,
          closeBtn: 1,
          content: routeData.href,
          end: function () {
            that.getNeeddoList("init");
            that.getAlreadydoList("init");
            that.getshijianList("init");
            that.getallTaskCounts();
            that.getremainingTaskCounts();
            that.gettodaysTaskCounts();
            that.gettaskCompletionRate();
          },
        });
      }else if(item.APPNAME == "EVENTINFORMATION"){
        
        let routeData = this.$router.resolve({
          path: "/productGeneral/generalApp/EVENTINFORMATION",
          query: {
            gotodetailid: item.APPDATANUM,
            type: "todo",
          },
        });
        console.log(routeData.href);
        layer.open({
          type: 2,
          area: ["90%", "90%"],
          title: item.TITLE,
          closeBtn: 1,
          content: routeData.href,
          end: function () {
            that.getNeeddoList("init");
            that.getAlreadydoList("init");
            that.getshijianList("init");
            that.getallTaskCounts();
            that.getremainingTaskCounts();
            that.gettodaysTaskCounts();
            that.gettaskCompletionRate();
          },
        });
      } else {
        this.$message.warning("此单据缺少单据ID和应用名称！");
      }
    },
    handlerTab(type, from) {
      if (from == "change") {
        this.searchList.nodename = "";
      }
      if (type == "needdo") {
        this.TodoTab = 1;
        this.page.currentPage = 1;
        this.getNeeddoList("init");
      } else if (type == "alreadydo") {
        this.TodoTab = 2;
        this.page.currentPage = 1;
        this.getAlreadydoList("init");
      } else if (type == "shijian") {
        this.TodoTab = 3;
        this.page.currentPage = 1;
        this.getshijianList("init");
      }
    },

    // 获取待办
    getNeeddoList(type) {
      var url =
        Config.dev_url +
        "/api-apps/workflow/todoAndDoneTask/findToDoTasks?user=" + this.currentuserinfo.USERNAME 
        // +
        // "&pageNum=" +
        // this.page.currentPage +
        // "&pageSize=" +
        // this.page.pageSize +
        // "&tododesc=" +
        // this.searchList.tododesc +
        // "&nodename=" +
        // this.searchList.nodename;
        let data = {
          pageNum:this.page.currentPage,
          pageSize:this.page.pageSize
        }
      this.$axios.post(url,data).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          if (type == "init") {
            this.TodoData = res.data.data;
          } else {
            this.TodoData = this.TodoData.concat(res.data.data);
          }
          this.todototal = Number(res.data.count);
          this.page.totaltodoCount = Number(res.data.count);
        }
      });
    },
    // 获取已办列表
    getAlreadydoList(type) {
      var url =
        Config.dev_url +
        "/api-apps/workflow/todoAndDoneTask/findDoneTasks?user=" + this.currentuserinfo.USERNAME
        // "&pageNum=" +
        // this.page.currentPage +
        // "&pageSize=" +
        // this.page.pageSize +
        // "&opinion=" +
        // this.searchList.opinion +
        // "&nodename=" +
        // this.searchList.nodename;
        let data = {
          pageNum:this.page.currentPage,
          pageSize:this.page.pageSize
        }
      this.$axios.post(url,data).then((res) => {
        if (res.data.code == 0) {
          if (type == "init") {
            this.DoneData = res.data.data;
          } else {
            this.DoneData = this.DoneData.concat(res.data.data);
          }
          this.donetotal = Number(res.data.count);
          this.page.totadoneCount = Number(res.data.count);
        }
      });
    },
    // 获取事件列表
    getshijianList(type) {
      var url =
        Config.dev_url +
        "/api-apps/app/REMINDER/list"
        let data = {
          page:this.page.currentPage,
          limit:this.page.pageSize,
          p_query: `{\"links\":[{\"key\":\"REMINDERUSER\",\"links\":[],\"type\":\"Like\",\"value\":\"${this.currentuserinfo.USERNAME}\"}],\"type\":\"LinkAnd\"}`
        }
      this.$axios.post(url,data).then((res) => {
        if (res.data.code == 0) {
          if (type == "init") {
            this.shijianData = res.data.data;
          } else {
            this.shijianData = this.shijianData.concat(res.data.data);
          }
          this.shijiantotal = Number(res.data.count);
          this.page.totashijianCount = Number(res.data.count);
        }
      });
    },
    // 查看更多
    LoadMore() {
      var nownum = this.page.currentPage * this.page.pageSize;
      // if (nownum < this.page.totalCount) {
      //     this.page.currentPage++;
      //     if (this.TodoTab == 1) {
      //         this.getNeeddoList();
      //     } else if (this.TodoTab == 2) {
      //         this.getAlreadydoList();
      //     }
      // }
      if (this.TodoTab == 1) {
        if (nownum < this.page.totaltodoCount) {
          this.page.currentPage++;
          this.getNeeddoList();
        }
      } else if (this.TodoTab == 2) {
        if (nownum < this.page.totadoneCount) {
          this.page.currentPage++;
          this.getAlreadydoList();
        }
      } else if (this.TodoTab == 3) {
        if (nownum < this.page.totadoneCount) {
          this.page.currentPage++;
          this.getshijianList();
        }
      }
    },

    // 搜索
    SearchBtn() {
      if (this.TodoTab == 1) {
        this.handlerTab("needdo");
      } else if (this.TodoTab == 2) {
        this.handlerTab("alreadydo");
      }else if (this.TodoTab == 3) {
        this.handlerTab("shijian");
      }
    },

    // 获取任务数据
    getallTaskCounts() {
      var url =
        Config.dev_url +
        "/api-apps/workflow/todoAndDoneTask/allTaskCounts?user=" +
        this.currentuserinfo.USERNAME;
      this.$axios.get(url).then((res) => {
        this.allTaskCounts = Number(res.data.data);
      });
    },

    getremainingTaskCounts() {
      var url =
        Config.dev_url +
        "/api-apps/workflow/todoAndDoneTask/remainingTaskCounts?user=" +
        this.currentuserinfo.USERNAME;
      this.$axios.get(url).then((res) => {
        this.remainingTaskCounts = Number(res.data.data);
      });
    },

    gettodaysTaskCounts() {
      var url =
        Config.dev_url +
        "/api-apps/workflow/todoAndDoneTask/todaysTaskCounts?user=" +
        this.currentuserinfo.USERNAME;
      this.$axios.get(url).then((res) => {
        this.todaysTaskCounts = Number(res.data.data);
      });
    },

    gettaskCompletionRate() {
      var url =
        Config.dev_url +
        "/api-apps/workflow/todoAndDoneTask/taskCompletionRate?user=" +
        this.currentuserinfo.USERNAME;
      this.$axios.get(url).then((res) => {
        console.log(res.data.data);
        var num = Number(res.data.data);
        this.taskCompletionRate = (num * 100).toFixed(2);
      });
    },

    // 管理
    ManageMenu() {
      this.Manage = !this.Manage;
    },

    getQuickList() {
      var url = Config.dev_url + "/api-user/menus/findUserCollectMenuList";
      this.$axios.get(url).then((res) => {
        this.quickmenuList = res.data;
      });
    },

    // 增加快捷入口
    addnewMenu() {
      this.$refs.quickmenu.view();
    },

    deletemenu(data) {
      var url =
        Config.dev_url +
        "/api-user/menus/delUserCollectMenu?menucodes=" +
        data.code;
      this.$axios.get(url).then((res) => {
        if (res.data.code == 0) {
          this.$message.success(res.data.msg);
          this.getQuickList();
        }
      });
    },

    sendquickmenuData(selectdatacodeList) {
      var menucodes = selectdatacodeList.join(",");
      var url =
        Config.dev_url +
        "/api-user/menus/saveUserCollectMenu?menucodes=" +
        menucodes;
      this.$axios.get(url).then((res) => {
        console.log(res, "增加结果");
        if (res.data.code == 0) {
          this.$message.success(res.data.msg);
          this.getQuickList();
        } else {
          // this.$message.warning(res.data.msg)
        }
      });
    },

    menuClickBtn(item) {
      console.log(item);
      this.$store.commit("setdefaultActiveIndex", String(item.id));
      //向子应用传递数据
      this.$actions.setGlobalState({
        currentuserinfo: this.$store.state.currentuserinfo,
        currentSelectMenuInfo: item,
        commonLanguageList:this.$store.state.commonLanguageList,
      });
      //跳转到通用解析页面
      if (item.menutype == "0" && !!item.associationkey) {
        this.$router.push({
          path: "/productGeneral/generalApp/" + item.associationkey,
          query: {
            now: new Date().getTime(),
          },
        });
      } else if (item.menutype == "1") {
        this.$router.push({
          path: "/productGeneral/GeneralReport/" + item.associationkey,
          query: {
            now: new Date().getTime(),
          },
        });
      } else {
        if (item.url.indexOf("getewayGeneral") != -1) {
          this.$nextTick(() => {
            let mainW = document.getElementById("vmMain").clientWidth;
            console.log(mainW);
            this.$actions.setGlobalState({ mainW: mainW }); //通过setGlobalState改变全
          });
        }
        this.$router.push({
          path: item.url,
          query: {
            now: new Date().getTime(),
          },
        });
      }
    },
  },
  mounted() {
    this.getNeeddoList("init");
    this.getAlreadydoList("init");
    this.getshijianList("init");
    this.TodoTab = 1;
    this.getallTaskCounts();
    this.getremainingTaskCounts();
    this.gettodaysTaskCounts();
    this.gettaskCompletionRate(), this.getQuickList();
  },
};
</script>

<style scoped lang="less">
#wrap {
  height: calc(100vh - 350px);
  border-radius: 2px;
  .row_wrap {
    width: 100%;
    .left {
      background: #fff;
      height: calc(100vh - 350px);
      flex-direction: column;
    //   box-shadow: 1px 1px 2px 0 rgba(82, 90, 102, 0.04),
    //     2px 2px 8px 0 rgba(82, 90, 102, 0.08);
      border-radius: 4px;
      .left_title {
        height: 64px;
        span {
          line-height: 64px;
          font-family: PingFangSC-Medium;
          font-size: 20px;
          color: #1d2129;
          letter-spacing: 0;
          font-weight: 500;
          margin-left: 19px;
        }
      }
      .task_wrap {
        height: 94px;
        display: flex;
        border-bottom: 1px solid #f2f3f5;
        .task_box {
          background: #fff;
          display: flex;
          align-items: center;
          flex: 1;
          padding: 20px;
          .img_wrap {
            width: 30px;
            height: 30px;
            // background: #f6f7fb;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 30px;
              height: 30px;
            }
          }

          .task_content {
            display: flex;
            flex-direction: column;
            flex: 1;
            height: 100%;
            justify-content: space-between;
            margin-left: 11px;
            .title {
              font-size: 12px;
              color: #1d2129;
              letter-spacing: 0;
              line-height: 20px;
              font-weight: 400;
            }
            .num_wrap {
              display: flex;
              align-items: end;
              .num {
                font-family: DINCondensed-Bold;
                font-size: 24px;
                color: #202328;
                line-height: 32px;
                font-weight: 700;
                margin-right: 4px;
              }
              .Symbol {
                font-size: 12px;
                color: #4e5969;
                letter-spacing: 0;
                line-height: 20px;
                font-weight: 400;
              }
            }
          }
        }
        .task_box:nth-child(1) .task_content {
          border-right: 1px solid #f2f3f5;
        }
      }
      .Todo_wrap {
        margin-top: 22px;
        padding: 0 20px;
        height: calc(100% - 104px);
        .Todo_header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .Todo_header_Tab {
            display: flex;
            align-items: center;
            .TodoTab {
              width: 107px;
              height: 30px;
              background: #fff;
              border-radius: 2px;

              font-size: 14px;
              color: #4e5969;
              letter-spacing: 0;
              line-height: 30px;
              font-weight: 400;
              display: flex;
              justify-content: center;
              cursor: pointer;
            }
            .TodoTab.active {
              color: #1c74f1;
              background: #e8f1fd;
            }
            .TodoTab:nth-child(2) {
              margin-left: 4px;
            }
          }
          .Todo_header_Search {
            display: flex;
            align-items: center;
            /deep/.el-input__inner {
              height: 32px;
            }
            /deep/.el-input__prefix .el-input__icon {
              line-height: 32px;
            }
            i {
              color: #4e5969;
              margin-left: 12px;
            }
          }
          /deep/.Todo_header_Search
            .el-input
            .el-input__suffix
            .el-input__suffix-inner
            .el-input__icon {
            line-height: 32px;
          }
        }
        .TodoList {
          height: calc(100% - 96px);
          overflow-y: scroll;
          margin-top: 10px;
          .TodoList_inner {
            padding: 0 16px;
          }
          .Todo_item {
            height: 50px;
            padding: 16px 16px;
            box-shadow: inset 0 -1px 0 0 #e5e6eb;
            display: flex;
           
            .Todo_item_title {
              font-weight: 600;
              height: 22px;
              min-width: 210px;
              line-height: 31px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 14px;
              color: #1d2129;
              // margin-right: 40px;
            }
            .Todo_content {
              display: flex;
              align-items: center;
              margin-top: 4px;
              height: 22px;
              line-height: 22px;
              font-size: 14px;
              color: #4e5969;
              span {
                margin-right: 16px;
                width: 160px;
              }
              .Todo_content_node {
                background: rgb(235, 243, 255);
                padding: 0px 8px;
                border-radius: 2px;
                font-size: 12px;
                color: #1c74f1;
              }
              .Todo_content_app {
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #202328;
                i {
                  margin-right: 6px;
                }
              }
              .Todo_content_time {
                color: rgb(18, 100, 163);
              }
            }
          }
          .Todo_item:hover {
            cursor: pointer;
            background: var(--theme-mode-bgcolor2);
          }
        }
        .readmore {
          height: 50px;
          background: #fff;

          font-size: 12px;
          color: #165dff;
          letter-spacing: 0;
          line-height: 20px;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
    .right {
      background: #eceff5;
      height: calc(100vh - 100px);
      margin-right: -16px;
      .notice {
        height: 172px;
        background: #fff;
        box-shadow: 1px 1px 2px 0 rgba(82, 90, 102, 0.04),
          2px 2px 8px 0 rgba(82, 90, 102, 0.08);
        border-radius: 4px;
        padding: 20px;
        .notice_header {
          display: flex;
          justify-content: space-between;
          .title {
            font-family: PingFangSC-Medium;
            font-size: 16px;
            color: #000000;
            letter-spacing: 0;
            line-height: 22px;
            font-weight: 500;
          }
          .readmore {
            font-size: 12px;
            color: #165dff;
            letter-spacing: 0;
            line-height: 20px;
            font-weight: 400;
            cursor: pointer;
          }
        }
        .notice_List {
          height: calc(100% - 25px);
          overflow: scroll;
          margin-top: 8px;
          .notice_item {
            display: flex;
            margin-top: 8px;
            .type {
              min-width: 44px;
              height: 20px;

              font-size: 14px;
              letter-spacing: 0;
              line-height: 22px;
              font-weight: 400;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .type.new {
              background: #fff7e8;
              color: #ff7d00;
            }
            .type.message {
              background: #e8fffb;
              color: #0fc6c2;
            }
            .type.notice1 {
              background: #e8f3ff;
              color: #165dff;
            }
            .content {
              font-size: 14px;
              color: #4e5969;
              letter-spacing: 0;
              line-height: 20px;
              font-weight: 400;
              margin-left: 7px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
      .quicklyEnter {
        margin-top: 16px;
        height: calc(100% - 268px);
        background: #ffffff;
        box-shadow: 1px 1px 2px 0 rgba(82, 90, 102, 0.04),
          2px 2px 8px 0 rgba(82, 90, 102, 0.08);
        border-radius: 4px;
        padding: 20px;
        overflow-y: scroll;
        .quicklyEnter_header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          .title {
            font-family: PingFangSC-Medium;
            font-size: 16px;
            color: #000000;
            letter-spacing: 0;
            line-height: 22px;
            font-weight: 500;
          }
          .readmore {
            font-size: 12px;
            color: #165dff;
            letter-spacing: 0;
            line-height: 20px;
            font-weight: 400;
            cursor: pointer;
          }
        }
        .quicklyEnter_header.recently {
          margin-top: 35px;
        }
        .quicklyEnter_box_wrap {
          display: flex;
          flex-wrap: wrap;
          .quicklyEnter_box {
            min-width: 64px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 33%;
            margin-bottom: 20px;
            position: relative;
            .deletemenu {
              background: #e63e4c;
              border-radius: 50%;
              color: white;
              width: 12px;
              height: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              position: absolute;
              // right: 25%;
              right: calc((100% - 42px) / 2);
              top: -10%;
              cursor: pointer;
              display: none;
            }
            .quicklyEnter_icon {
              width: 32px;
              height: 32px;
              border-radius: 3px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              i {
                color: white;
              }
            }
            .quicklyEnter_icon.blue {
              background: #1c74f1;
            }
            .quicklyEnter_icon.blue2 {
              background: #07acfc;
            }
            .quicklyEnter_icon.orange {
              background: #fba308;
            }
            .quicklyEnter_icon.green {
              background: #46c737;
            }
            .quicklyEnter_icon.purple {
              background: #846bce;
            }
            .quicklyEnter_icon.add {
              border: 1px dotted black;
              i {
                color: black;
              }
            }
            .quicklyEnter_icon.add:hover,
            .quicklyEnter_icon.add:hover + .addtitle {
              scale: 1.1;
            }
            .quicklyEnter_icon:hover .deletemenu {
              display: block;
            }

            .quicklyEnter_name {
              text-align: center;
              width: 100%;

              font-size: 12px;
              color: #424e66;
              letter-spacing: 0;
              line-height: 20px;
              font-weight: 400;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>
